import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, query, where, getDocs, collection, addDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { db, auth, storage } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage, faUser, faEnvelope, faGlobe, faList, faClock, faVenusMars, faBriefcase, faIndustry,
  faLink, faTasks, faCalendar, faUpload, faPaperPlane, faPlus, faTimes, faArrowLeft, faVideo,
  faQuestionCircle, faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { onAuthStateChanged } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const themeColors = {
  primary: '#007bff',
  secondary: '#007bff',
  background: '#f5f7fa',
  text: '#333333',
  lightText: '#666666',
  border: '#e1e4e8',
  success: '#007bff',
  danger: '#007bff',
  warning: '#007bff',
};

const styles = {
  container: {
    backgroundColor: themeColors.background,
    minHeight: '100vh',
    padding: '2rem',
    fontFamily: 'Arial, sans-serif',
  },
  formContainer: {
    maxWidth: '900px',
    margin: '0 auto',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  formContent: {
    padding: '2rem',
  },
  heading: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center',
    color: themeColors.primary,
  },
  subHeading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: themeColors.primary,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1.5rem',
  },
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    fontWeight: '500',
    color: themeColors.text,
  },
  input: {
    width: '100%',
    padding: '0.75rem',
    border: `1px solid ${themeColors.border}`,
    borderRadius: '0.25rem',
    fontSize: '1rem',
    transition: 'border-color 0.2s ease-in-out',
  },
  select: {
    width: '100%',
    padding: '0.75rem',
    border: `1px solid ${themeColors.border}`,
    borderRadius: '0.25rem',
    fontSize: '1rem',
    appearance: 'none',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 12 12'%3E%3Cpath d='M6 9L1 4h10z'/%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0.75rem center',
    backgroundSize: '0.75rem',
  },
  button: {
    padding: '0.75rem 1.5rem',
    backgroundColor: themeColors.primary,
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
  },
  deleteButton: {
    padding: '0.5rem',
    backgroundColor: themeColors.danger,
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    fontSize: '0.875rem',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
  },
  skillTag: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: themeColors.secondary,
    color: 'white',
    padding: '0.25rem 0.5rem',
    borderRadius: '1rem',
    fontSize: '0.875rem',
    marginRight: '0.5rem',
    marginBottom: '0.5rem',
  },
  skillTagCloseButton: {
    marginLeft: '0.25rem',
    color: 'white',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  videoContainer: {
    marginTop: '1rem',
    border: `2px dashed ${themeColors.border}`,
    borderRadius: '0.5rem',
    padding: '1rem',
    textAlign: 'center',
  },
  video: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: '0.5rem',
  },
};

const CustomAlert = ({ title, children, type = 'info' }) => {
  const alertStyles = {
    container: {
      padding: '1rem',
      borderRadius: '0.5rem',
      marginBottom: '1rem',
      color: 'white',
    },
    title: {
      fontWeight: 'bold',
      marginBottom: '0.5rem',
    },
  };

  const alertColors = {
    info: themeColors.primary,
    success: themeColors.success,
    warning: themeColors.warning,
    error: themeColors.danger,
  };

  return (
    <div style={{ ...alertStyles.container, backgroundColor: alertColors[type] }}>
      <p style={alertStyles.title}>{title}</p>
      <p>{children}</p>
    </div>
  );
};

const categories = [
  'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
  'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
  'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
  'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
];

const skillsByCategory = {
  "Marketing": ["SEO", "Digital Advertising", "Content Strategy", "Social Media", "Email Marketing", "Brand Management", "Analytics", "PPC", "Content Creation", "Video Marketing"],
  "Fullstack Engineer": ["JavaScript", "React", "Node.js", "SQL", "NoSQL", "CSS", "HTML", "Angular", "Vue.js", "Python"],
  "Data Science": ["Python", "R", "SQL", "Machine Learning", "Deep Learning", "Data Visualization", "Big Data", "Statistics", "Data Mining", "NLP"],
  "Product Management": ["Agile", "Scrum", "User Stories", "Roadmapping", "A/B Testing", "Product Strategy", "User Research", "Wireframing", "Prototyping", "Stakeholder Management"],
  "Graphic Design": ["Adobe Photoshop", "Adobe Illustrator", "InDesign", "Typography", "Logo Design", "Branding", "Color Theory", "Print Design", "Digital Illustration", "UI Design"],
  "UI/UX Design": ["Wireframing", "Prototyping", "User Research", "Usability Testing", "Information Architecture", "Interaction Design", "Visual Design", "Sketch", "Figma", "Adobe XD"],
  "Software Development": ["Java", "Python", "C++", "C#", "Ruby", "Go", "Swift", "Kotlin", "PHP", "Rust"],
  "Project Management": ["Agile", "Scrum", "Kanban", "PRINCE2", "PMP", "Risk Management", "Stakeholder Management", "Budgeting", "Resource Allocation", "Gantt Charts"],
  "Sales": ["Negotiation", "CRM", "Lead Generation", "Cold Calling", "Account Management", "Sales Strategy", "Presentations", "Closing Techniques", "Pipeline Management", "Forecasting"],
  "Customer Support": ["Ticketing Systems", "Live Chat", "Phone Support", "Email Support", "Customer Service", "Conflict Resolution", "Product Knowledge", "Active Listening", "Empathy", "Problem-Solving"],
  "Human Resources": ["Recruitment", "Employee Relations", "Performance Management", "Training & Development", "Compensation & Benefits", "HRIS", "Labor Laws", "Onboarding", "Succession Planning", "Diversity & Inclusion"],
  "Finance": ["Financial Analysis", "Budgeting", "Forecasting", "Accounting", "Financial Modeling", "Risk Management", "Investment Analysis", "Tax Planning", "Audit", "Mergers & Acquisitions"],
  "Legal": ["Contract Law", "Intellectual Property", "Corporate Law", "Litigation", "Regulatory Compliance", "Legal Research", "Negotiation", "Mediation", "Legal Writing", "Ethics"],
  "Education": ["Curriculum Development", "Instructional Design", "E-learning", "Assessment", "Classroom Management", "Special Education", "Educational Technology", "Pedagogy", "Student Counseling", "STEM Education"],
  "Healthcare": ["Patient Care", "Electronic Health Records", "Medical Coding", "Clinical Research", "Healthcare Administration", "Nursing", "Pharmacology", "Medical Imaging", "Public Health", "Telemedicine"],
  "Real Estate": ["Property Valuation", "Real Estate Law", "Property Management", "Commercial Real Estate", "Residential Real Estate", "Real Estate Finance", "Land Development", "Real Estate Marketing", "Leasing", "Property Inspection"],
  "Logistics": ["Supply Chain Management", "Inventory Management", "Warehousing", "Transportation Management", "Freight Forwarding", "Customs Compliance", "Demand Planning", "Route Optimization", "Logistics Software", "Cold Chain Logistics"],
  "Manufacturing": ["Lean Manufacturing", "Six Sigma", "Quality Control", "Process Improvement", "Production Planning", "Inventory Management", "CAD/CAM", "CNC Programming", "3D Printing", "Industrial Automation"],
  "Hospitality": ["Hotel Management", "Food & Beverage Management", "Event Planning", "Customer Service", "Revenue Management", "Housekeeping", "Front Office Operations", "Reservation Systems", "Hospitality Marketing", "Tourism Management"],
  "Other": ["Communication", "Leadership", "Time Management", "Critical Thinking", "Adaptability", "Teamwork", "Problem Solving", "Creativity", "Emotional Intelligence", "Decision Making"]
};

const timezoneMapping = {
  "USA": ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"],
  "Canada": ["America/Toronto", "America/Vancouver", "America/Edmonton", "America/Halifax"],
  "United Kingdom": ["Europe/London"],
  "Australia": ["Australia/Sydney", "Australia/Melbourne", "Australia/Perth", "Australia/Brisbane"],
  "Germany": ["Europe/Berlin"],
  "France": ["Europe/Paris"],
  "Japan": ["Asia/Tokyo"],
  "India": ["Asia/Kolkata"],
  "Brazil": ["America/Sao_Paulo"],
  "Mexico": ["America/Mexico_City"],
  "Other": ["UTC"]
};

const PERSONALITY_QUESTIONS = [
  'How do you handle conflict?',
  'How do you approach new challenges?',
  'How do you motivate others?',
  'How do you prefer to communicate?',
  'How do you handle a stressful situation?',
  'How do you prefer to work in a team?',
  'How do you approach tasks at work?',
  'How do you make decisions?',
  'How do you respond to criticism?',
  'What role do you prefer in a project?',
  'How do you react to tight deadlines?',
  'What motivates you the most at work?',
  'How do you handle change?',
  'How do you solve problems?',
  'What is your approach to learning new skills?'
];

const PERSONALITY_OPTIONS = [
  ['I address it head-on and seek to resolve it quickly.', 'I try to find a compromise that works for everyone.', 'I prefer to avoid it and maintain harmony.', 'I analyze the situation before taking any action.'],
  ['I take them on eagerly and push for quick results.', 'I collaborate with others to find the best solution.', 'I proceed cautiously and maintain consistency.', 'I thoroughly plan and consider all options before acting.'],
  ['By setting clear goals and expectations.', 'By encouraging and inspiring them with enthusiasm.', 'By offering support and creating a stable environment.', 'By providing detailed instructions and guidance.'],
  ['Directly and to the point.', 'With energy and excitement.', 'Calmly and thoughtfully.', 'Clearly and with detailed information.'],
  ['I tackle it immediately and assertively.', 'I stay positive and try to uplift others.', 'I remain calm and look for steady solutions.', 'I analyze the problem and develop a strategic plan.'],
  ['As the leader, driving the team forward.', 'As a connector, bringing people together.', 'As a reliable team member, ensuring stability.', 'As the detail-oriented one, ensuring quality.'],
  ['Quickly and efficiently, focusing on results.', 'Enthusiastically, involving others.', 'Consistently, with a steady pace.', 'Carefully, ensuring accuracy and quality.'],
  ['Decisively and independently.', 'By gathering input from others.', 'After considering everyone\'s feelings.', 'Based on thorough analysis and data.'],
  ['I take it as a challenge to improve.', 'I discuss it with others to understand their perspective.', 'I reflect on it and try to see the positive side.', 'I analyze the feedback carefully and plan my improvements.'],
  ['The one in charge, setting direction.', 'The one fostering team spirit and motivation.', 'The one ensuring everyone is supported and tasks are completed.', 'The one focusing on details and ensuring high standards.'],
  ['I push hard to get things done on time.', 'I stay positive and encourage the team to meet the deadline.', 'I maintain a steady pace to avoid errors.', 'I plan meticulously to meet the deadline without compromising quality.'],
  ['Achieving goals and getting results.', 'Building relationships and being recognized.', 'Stability and a supportive environment.', 'Precision and high-quality work.'],
  ['I adapt quickly and take charge of new situations.', 'I embrace it enthusiastically and get others on board.', 'I prefer gradual changes and maintaining consistency.', 'I analyze the changes carefully before adapting.'],
  ['By making quick decisions and acting on them.', 'By brainstorming with others and finding creative solutions.', 'By staying calm and finding a steady approach.', 'By thoroughly analyzing the problem and developing a detailed plan.'],
  ['I prefer learning through practical experience and challenges.', 'I enjoy interactive and social learning environments.', 'I like structured and consistent learning processes.', 'I focus on detailed and comprehensive learning materials.']
];

function EditProfile() {
  const navigate = useNavigate();
  const [profileInfo, setProfileInfo] = useState({
    profileImage: '',
    name: '',
    email: '',
    country: '',
    timezone: '',
    gender: '',
    currentJobTitle: '',
    industryCategory: '',
    workExperience: [{
      title: '',
      spanStart: new Date(),
      spanEnd: new Date(),
      description: ''
    }],
    primarySkills: [],
    availableSkills: [],
    socialLinks: [{ url: '' }],
    certifications: [{ title: '', issuer: '', date: new Date() }],
    linkedin: '',
    cvUrl: '',
    videoIntroUrl: ''
  });

  const [cvPreview, setCvPreview] = useState('');
  const [videoPreview, setVideoPreview] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const videoRef = useRef(null);
  const [personalityAnswers, setPersonalityAnswers] = useState(Array(PERSONALITY_QUESTIONS.length).fill(''));
  const [personalityReport, setPersonalityReport] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProfile();
      } else {
        console.log('User is not logged in');
        navigate('/expertlogin');
      }
    });

    return () => unsubscribe();

  }, [navigate]);

  const fetchProfile = async () => {
    if (!auth.currentUser || !auth.currentUser.email) {
      console.log('No user logged in');
      navigate('/expertlogin');
      return;
    }

    const userEmail = auth.currentUser.email;

    try {
      let docRef = doc(db, "profiles", userEmail);
      let docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        const queryRef = query(collection(db, "profiles"), where("email", "==", userEmail));
        const querySnap = await getDocs(queryRef);

        if (!querySnap.empty) {
          docSnap = querySnap.docs[0];
        }
      }

      if (docSnap.exists()) {
        let data = docSnap.data();
        data.workExperience = data.workExperience.map(exp => ({
          ...exp,
          spanStart: exp.spanStart ? new Date(exp.spanStart.seconds * 1000) : new Date(),
          spanEnd: exp.spanEnd ? new Date(exp.spanEnd.seconds * 1000) : new Date(),
        }));
        data.certifications = data.certifications.map(cert => ({
          ...cert,
          date: cert.date ? new Date(cert.date.seconds * 1000) : new Date(),
        }));
        setProfileInfo(data);
        setCvPreview(data.cvUrl);
        setVideoPreview(data.videoIntroUrl);
        if (data.personalityAnswers) {
          setPersonalityAnswers(data.personalityAnswers);
        }
        if (data.personalityReport) {
          setPersonalityReport(data.personalityReport);
        }
      } else {
        console.log("No profile document found for the email:", userEmail);
      }
    } catch (error) {
      console.error("Error fetching profile: ", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const handleChangeCountry = (e) => {
    const selectedCountry = e.target.value;
    const selectedTimezones = timezoneMapping[selectedCountry] || [];
    setProfileInfo(prevState => ({
      ...prevState,
      country: selectedCountry,
      timezone: selectedTimezones.length === 1 ? selectedTimezones[0] : ''
    }));
  };

  const handleChangeTimezone = (e) => {
    setProfileInfo(prevState => ({ ...prevState, timezone: e.target.value }));
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    const categorySkills = skillsByCategory[selectedCategory] || [];
    setProfileInfo(prevState => ({
      ...prevState,
      industryCategory: selectedCategory,
      availableSkills: categorySkills,
      primarySkills: []
    }));
  };

  const handleSkillsSelect = (e) => {
    const skill = e.target.value;
    if (skill && !profileInfo.primarySkills.includes(skill)) {
      setProfileInfo(prevState => ({
        ...prevState,
        primarySkills: [...prevState.primarySkills, skill]
      }));
    }
  };

  const removeSkill = (skillToRemove) => {
    setProfileInfo(prevState => ({
      ...prevState,
      primarySkills: prevState.primarySkills.filter(skill => skill !== skillToRemove)
    }));
  };

  const handleSocialLinkChange = (index, newUrl) => {
    const updatedLinks = profileInfo.socialLinks.map((link, i) => 
      i === index ? { ...link, url: newUrl } : link
    );
    setProfileInfo(prevState => ({ ...prevState, socialLinks: updatedLinks }));
  };

  const addSocialLink = () => {
    setProfileInfo(prevState => ({
      ...prevState,
      socialLinks: [...prevState.socialLinks, { url: '' }]
    }));
  };

  const removeSocialLink = (index) => {
    setProfileInfo(prevState => ({
      ...prevState,
      socialLinks: prevState.socialLinks.filter((_, i) => i !== index)
    }));
  };

  const handleWorkExperienceChange = (index, field, value) => {
    const newWorkExperience = [...profileInfo.workExperience];
    newWorkExperience[index][field] = value;
    setProfileInfo(prevState => ({ ...prevState, workExperience: newWorkExperience }));
  };

  const addWorkExperience = () => {
    setProfileInfo(prevState => ({
      ...prevState,
      workExperience: [...prevState.workExperience, { title: '', spanStart: new Date(), spanEnd: new Date(), description: '' }]
    }));
  };

  const removeWorkExperience = (index) => {
    setProfileInfo(prevState => ({
      ...prevState,
      workExperience: prevState.workExperience.filter((_, i) => i !== index)
    }));
  };

  const handleCertificationChange = (index, field, value) => {
    const updatedCertifications = profileInfo.certifications.map((cert, i) => 
      i === index ? { ...cert, [field]: value } : cert
    );
    setProfileInfo(prevState => ({ ...prevState, certifications: updatedCertifications }));
  };

  const addCertification = () => {
    setProfileInfo(prevState => ({
      ...prevState,
      certifications: [...prevState.certifications, { title: '', issuer: '', date: new Date() }]
    }));
  };

  const removeCertification = (index) => {
    setProfileInfo(prevState => ({
      ...prevState,
      certifications: prevState.certifications.filter((_, i) => i !== index)
    }));
  };

  const handleProfileImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `profileImages/${auth.currentUser.uid}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      setProfileInfo(prevState => ({ ...prevState, profileImage: fileUrl }));
    }
  };

  const handleCvUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `cvs/${auth.currentUser.uid}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      setCvPreview(fileUrl);
      setProfileInfo(prevState => ({ ...prevState, cvUrl: fileUrl }));
    }
  };

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    const chunks = [];
    recorder.ondataavailable = (event) => chunks.push(event.data);
    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'video/webm' });
      setVideoBlob(blob);
      setVideoPreview(URL.createObjectURL(blob));
    };

    recorder.start();
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
      setIsRecording(false);
    }
  };

  const handleVideoUpload = async () => {
    if (videoBlob) {
      const fileRef = storageRef(storage, `videos/${auth.currentUser.uid}/${Date.now()}.webm`);
      await uploadBytes(fileRef, videoBlob);
      const downloadURL = await getDownloadURL(fileRef);
      setProfileInfo(prevState => ({ ...prevState, videoIntroUrl: downloadURL }));
    }
  };

  const handlePersonalityAnswer = (index, answer) => {
    const newAnswers = [...personalityAnswers];
    newAnswers[index] = answer;
    setPersonalityAnswers(newAnswers);
  };

  const generatePersonalityReport = (answers) => {
    const typeScores = { Dominance: 0, Influence: 0, Steadiness: 0, Conscientiousness: 0 };

    answers.forEach((answer, index) => {
      if (PERSONALITY_OPTIONS[index][0] === answer) typeScores.Dominance += 1;
      if (PERSONALITY_OPTIONS[index][1] === answer) typeScores.Influence += 1;
      if (PERSONALITY_OPTIONS[index][2] === answer) typeScores.Steadiness += 1;
      if (PERSONALITY_OPTIONS[index][3] === answer) typeScores.Conscientiousness += 1;
    });

    const dominantType = Object.keys(typeScores).reduce((a, b) => typeScores[a] > typeScores[b] ? a : b);

    const pronoun = profileInfo.gender === 'Male' ? 'He' : profileInfo.gender === 'Female' ? 'She' : 'They';
    const possessivePronoun = profileInfo.gender === 'Male' ? 'His' : profileInfo.gender === 'Female' ? 'Her' : 'Their';

    let report = `${profileInfo.name}'s personality analysis reveals a dominant ${dominantType} type. ${pronoun} excels in `;

    switch (dominantType) {
      case 'Dominance':
        report += `taking charge and driving results. ${possessivePronoun} direct approach and decisive nature make ${profileInfo.gender === 'Other' ? 'them' : 'him/her'} well-suited for leadership roles and high-pressure situations.`;
        break;
      case 'Influence':
        report += `building relationships and inspiring others. ${possessivePronoun} enthusiasm and people-oriented approach make ${profileInfo.gender === 'Other' ? 'them' : 'him/her'} a great team motivator and communicator.`;
        break;
      case 'Steadiness':
        report += `providing stability and support. ${possessivePronoun} calm demeanor and consistent work style make ${profileInfo.gender === 'Other' ? 'them' : 'him/her'} a reliable team member and excellent at maintaining harmony.`;
        break;
      case 'Conscientiousness':
        report += `ensuring accuracy and maintaining high standards. ${possessivePronoun} analytical approach and attention to detail make ${profileInfo.gender === 'Other' ? 'them' : 'him/her'} well-suited for roles requiring precision and thorough planning.`;
        break;
    }

    return { report, dominantType };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (personalityAnswers.some(answer => answer === '')) {
      toast.error('Please answer all personality questions before submitting.');
      setIsLoading(false);
      return;
    }

    try {
      let videoUrl = profileInfo.videoIntroUrl;
      if (videoBlob) {
        const fileRef = storageRef(storage, `videos/${auth.currentUser.uid}/${Date.now()}_video.webm`);
        await uploadBytes(fileRef, videoBlob);
        videoUrl = await getDownloadURL(fileRef);
      }

      const { report, dominantType } = generatePersonalityReport(personalityAnswers);

      const updatedProfileInfo = {
        ...profileInfo,
        videoIntroUrl: videoUrl,
        personalityReport: report,
        personalityType: dominantType,
        personalityAnswers: personalityAnswers
      };

      await setDoc(doc(db, 'profiles', auth.currentUser.uid), updatedProfileInfo);
      
      await addDoc(collection(db, "expertsNotification"), {
        userId: auth.currentUser.uid,
        email: profileInfo.email,
        message: "Your Profile has been updated",
        read: false,
        timestamp: new Date(),
      });

      setPersonalityReport(report);
      toast.success('Profile updated successfully!');
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating profile: ", error);
      toast.error('Failed to update profile: ' + error.message);
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <div style={styles.formContent}>
          <h1 style={styles.heading}>Update Your Expert Profile</h1>
          
          <CustomAlert title="Enhance your profile" type="info">
            Complete all sections to better match opportunities that align with your skills and experience.
          </CustomAlert>

          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <div style={styles.gridContainer}>
              <div>
                <label style={styles.label}>Profile Image</label>
                <input 
                  type="file" 
                  accept="image/*" 
                  onChange={handleProfileImageUpload} 
                  style={styles.input} 
                  required 
                />
                {profileInfo.profileImage && (
                  <img 
                    src={profileInfo.profileImage} 
                    alt="Profile" 
                    style={{ 
                      marginTop: '1rem', 
                      width: '8rem', 
                      height: '8rem', 
                      objectFit: 'cover', 
                      borderRadius: '50%' 
                    }} 
                  />
                )}
              </div>
              <div>
                <label style={styles.label}>Name</label>
                <input 
                  type="text" 
                  name="name" 
                  value={profileInfo.name} 
                  onChange={handleChange} 
                  style={styles.input} 
                  required 
                />
              </div>
              <div>
                <label style={styles.label}>Email</label>
                <input 
                  type="email" 
                  name="email" 
                  value={profileInfo.email} 
                  onChange={handleChange} 
                  style={styles.input} 
                  required 
                />
              </div>
              <div>
                <label style={styles.label}>Country</label>
                <select 
                  name="country" 
                  value={profileInfo.country} 
                  onChange={handleChangeCountry} 
                  style={styles.select} 
                  required
                >
                  <option value="">Select Country</option>
                  {Object.keys(timezoneMapping).map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </select>
              </div>
              <div>
                <label style={styles.label}>Timezone</label>
                <select 
                  name="timezone" 
                  value={profileInfo.timezone} 
                  onChange={handleChangeTimezone} 
                  style={styles.select} 
                  required
                >
                  <option value="">Select Timezone</option>
                  {timezoneMapping[profileInfo.country]?.map(timezone => (
                    <option key={timezone} value={timezone}>{timezone}</option>
                  ))}
                </select>
              </div>
              <div>
                <label style={styles.label}>Gender</label>
                <select 
                  name="gender" 
                  value={profileInfo.gender} 
                  onChange={handleChange} 
                  style={styles.select}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div>
                <label style={styles.label}>Current Position</label>
                <input 
                  type="text" 
                  name="currentJobTitle" 
                  value={profileInfo.currentJobTitle} 
                  onChange={handleChange} 
                  style={styles.input} 
                  required 
                />
              </div>
              <div>
                <label style={styles.label}>Industry Category</label>
                <select 
                  name="industryCategory" 
                  value={profileInfo.industryCategory} 
                  onChange={handleCategoryChange} 
                  style={styles.select} 
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label style={styles.label}>Skills</label>
              <select 
                onChange={handleSkillsSelect} 
                style={styles.select}
              >
                <option value="">Select a skill</option>
                {profileInfo.availableSkills.map(skill => (
                  <option key={skill} value={skill}>{skill}</option>
                ))}
              </select>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginTop: '1rem' }}>
                {profileInfo.primarySkills.map(skill => (
                  <span key={skill} style={styles.skillTag}>
                    {skill}
                    <button 
                      type="button" 
                      onClick={() => removeSkill(skill)} 
                      style={styles.skillTagCloseButton}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            </div>

            <div>
              <label style={styles.label}>LinkedIn URL</label>
              <input 
                type="url" 
                name="linkedin" 
                value={profileInfo.linkedin} 
                onChange={handleChange} 
                style={styles.input} 
                placeholder="LinkedIn Profile URL" 
              />
            </div>

            <div>
              <label style={styles.label}>Social Media Links</label>
              {profileInfo.socialLinks.map((link, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
                  <input 
                    type="url" 
                    value={link.url} 
                    onChange={(e) => handleSocialLinkChange(index, e.target.value)} 
                    style={{ ...styles.input, flexGrow: 1 }} 
                    placeholder="Social Media URL" 
                  />
                  <button 
                    type="button" 
                    onClick={() => removeSocialLink(index)} 
                    style={styles.deleteButton}
                  >
                    ×
                  </button>
                </div>
              ))}
              <button 
                type="button" 
                onClick={addSocialLink} 
                style={{ ...styles.button, backgroundColor: themeColors.secondary }}
              >
                Add Social Link
              </button>
            </div>

            <div>
              <h2 style={styles.subHeading}>Work Experience</h2>
              {profileInfo.workExperience.map((experience, index) => (
                <div key={index} style={{ 
                  marginBottom: '1.5rem', 
                  padding: '1rem', 
                  backgroundColor: themeColors.background, 
                  borderRadius: '0.5rem' 
                }}>
                  <input 
                    type="text" 
                    value={experience.title} 
                    onChange={(e) => handleWorkExperienceChange(index, 'title', e.target.value)} 
                    style={styles.input} 
                    placeholder="Position Title" 
                    required 
                  />
                  <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                    <div style={{ flexGrow: 1 }}>
                      <label style={styles.label}>Start Date</label>
                      <DatePicker
                        selected={experience.spanStart}
                        onChange={(date) => handleWorkExperienceChange(index, 'spanStart', date)}
                        style={styles.input}
                        dateFormat="MMMM d, yyyy"
                        required
                      />
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <label style={styles.label}>End Date</label>
                      <DatePicker
                        selected={experience.spanEnd}
                        onChange={(date) => handleWorkExperienceChange(index, 'spanEnd', date)}
                        style={styles.input}
                        dateFormat="MMMM d, yyyy"
                        required
                      />
                    </div>
                  </div>
                  <textarea 
                    value={experience.description} 
                    onChange={(e) => handleWorkExperienceChange(index, 'description', e.target.value)} 
                    style={{ ...styles.input, marginTop: '1rem', minHeight: '100px' }} 
                    placeholder="Key Responsibilities" 
                    required
                  ></textarea>
                  <button 
                    type="button" 
                    onClick={() => removeWorkExperience(index)} 
                    style={{ ...styles.button, backgroundColor: themeColors.danger, marginTop: '1rem' }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button 
                type="button" 
                onClick={addWorkExperience} 
                style={{ ...styles.button, backgroundColor: themeColors.secondary }}
              >
                Add Work Experience
              </button>
            </div>

            <div>
              <h2 style={styles.subHeading}>Certifications</h2>
              {profileInfo.certifications.map((cert, index) => (
                <div key={index} style={{ 
                  marginBottom: '1.5rem', 
                  padding: '1rem', 
                  backgroundColor: themeColors.background, 
                  borderRadius: '0.5rem' 
                }}>
                  <input 
                    type="text" 
                    value={cert.title} 
                    onChange={(e) => handleCertificationChange(index, 'title', e.target.value)} 
                    style={styles.input} 
                    placeholder="Certification Title" 
                  />
                  <input 
                    type="text" 
                    value={cert.issuer} 
                    onChange={(e) => handleCertificationChange(index, 'issuer', e.target.value)} 
                    style={{ ...styles.input, marginTop: '1rem' }} 
                    placeholder="Issuer" 
                  />
                  <div style={{ marginTop: '1rem' }}>
                    <label style={styles.label}>Date</label>
                    <DatePicker
                      selected={cert.date}
                      onChange={(date) => handleCertificationChange(index, 'date', date)}
                      style={styles.input}
                      dateFormat="MMMM d, yyyy"
                    />
                  </div>
                  <button 
                    type="button" 
                    onClick={() => removeCertification(index)} 
                    style={{ ...styles.button, backgroundColor: themeColors.danger, marginTop: '1rem' }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button 
                type="button" 
                onClick={addCertification} 
                style={{ ...styles.button, backgroundColor: themeColors.secondary }}
              >
                Add Certification
              </button>
            </div>

            <div>
              <label style={styles.label}>CV Upload</label>
              <input 
                type="file" 
                accept=".pdf,.doc,.docx" 
                onChange={handleCvUpload} 
                style={styles.input} 
                required 
              />
              {cvPreview && (
                <a 
                  href={cvPreview} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{ 
                    display: 'inline-block', 
                    marginTop: '1rem', 
                    color: themeColors.primary, 
                    textDecoration: 'none' 
                  }}
                >
                  View CV
                </a>
              )}
            </div>

            <div>
              <label style={styles.label}>Video Introduction</label>
              <div style={styles.videoContainer}>
                <video ref={videoRef} style={{ display: isRecording ? 'block' : 'none', ...styles.video }}></video>
                {videoPreview && (
                  <video src={videoPreview} controls style={styles.video}></video>
                )}
                {!isRecording && !videoPreview && (
                  <p style={{ color: themeColors.lightText }}>Record a video introduction (max 3 minutes)</p>
                )}
                <div style={{ marginTop: '1rem' }}>
                  {isRecording ? (
                    <button 
                      type="button" 
                      onClick={handleStopRecording} 
                      style={{ ...styles.button, backgroundColor: themeColors.danger }}
                    >
                      Stop Recording
                    </button>
                  ) : (
                    <button 
                      type="button" 
                      onClick={handleStartRecording} 
                      style={styles.button}
                    >
                      Start Recording
                    </button>
                  )}
                  {videoPreview && (
                    <button 
                      type="button" 
                      onClick={handleVideoUpload} 
                      style={{ ...styles.button, backgroundColor: themeColors.success, marginLeft: '1rem' }}
                    >
                      Upload Video
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div>
              <h2 style={styles.subHeading}>Personality Assessment</h2>
              {PERSONALITY_QUESTIONS.map((question, index) => (
                <div key={index} style={{ marginBottom: '1.5rem' }}>
                  <label style={styles.label}>{question}</label>
                  <select 
                    value={personalityAnswers[index]} 
                    onChange={(e) => handlePersonalityAnswer(index, e.target.value)}
                    style={styles.select}
                    required
                  >
                    <option value="">Select an answer</option>
                    {PERSONALITY_OPTIONS[index].map((option, optionIndex) => (
                      <option key={optionIndex} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              ))}
            </div>

            {personalityReport && (
              <CustomAlert title="Your Personality Report" type="success">
                {personalityReport}
              </CustomAlert>
            )}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                type="submit"
                style={{
                  ...styles.button,
                  backgroundColor: isLoading ? themeColors.secondary : themeColors.primary,
                  cursor: isLoading ? 'not-allowed' : 'pointer',
                }}
                disabled={isLoading}
              >
                {isLoading ? 'Updating...' : 'Update Profile'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditProfile;