import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, updateDoc, doc, addDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import ClientNavbar from './clientnavbar';
import heroImage from '../assets/images/hero.jpg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faEdit,
  faCalendar,
  faUser,
  faEnvelope,
  faPhone,
  faBriefcase,
  faDollarSign,
  faBuilding,
  faInfoCircle,
  faClock,
  faDownload,
  faSignature,
  faVideo,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import SignatureCanvas from 'react-signature-canvas';
import { Chart } from 'chart.js/auto';
import toast, { Toaster } from 'react-hot-toast';

const stripePromise = loadStripe('pk_test_51PJcw62L5LTeMJgucbFgthFQmBUE1Hm7ZCKck5lwp6RJESyxOviyluvNMdboSnQFvRBa7mTFMkW90r171HlubENP00OuF9qi9Q');

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SectionTitle = styled.h3`
  margin-bottom: 20px;
  color: rgb(10, 88, 202);
  font-weight: bold;
  font-size: 1.8rem;
  text-transform: uppercase;
`;

const DetailItem = styled.p`
  margin: 0;
  font-size: 1rem;
  margin-bottom: 12px;
  color: #555;
`;

const ContractPDF = ({ contract }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Contract Agreement</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Client Email:</Text>
        <Text>{contract.clientEmail}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Expert Email:</Text>
        <Text>{contract.expertEmail}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Job Title:</Text>
        <Text>{contract.jobTitle}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Hire Date:</Text>
        <Text>{contract.createdAt ? new Date(contract.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Terms:</Text>
        <Text>{contract.terms}</Text>
      </View>
      <View style={styles.signatureSection}>
        <Text style={styles.signatureLabel}>Client Signature:</Text>
        <Text style={styles.signature}>{contract.clientName}</Text>
      </View>
      {contract.expertSignature && (
        <View style={styles.signatureSection}>
          <Text style={styles.signatureLabel}>Expert Signature:</Text>
          <Image style={styles.signatureImage} src={contract.expertSignature} />
        </View>
      )}
      {contract.signingDate && (
        <View style={styles.section}>
          <Text style={styles.label}>Signing Date:</Text>
          <Text>{new Date(contract.signingDate).toLocaleDateString()}</Text>
        </View>
      )}
      {contract.signingTime && (
        <View style={styles.section}>
          <Text style={styles.label}>Signing Time:</Text>
          <Text>{new Date(contract.signingTime).toLocaleTimeString()}</Text>
        </View>
      )}
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    textAlign: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
  },
  signatureSection: {
    marginTop: 20,
  },
  signatureLabel: {
    fontWeight: 'bold',
  },
  signature: {
    fontStyle: 'italic',
  },
  signatureImage: {
    width: 200,
    height: 100,
  },
});

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  color: rgb(10, 88, 202);
  font-size: 1rem;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  max-height: 90vh;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.8rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: rgb(10, 88, 202);
`;

const PopupTitle = styled.h2`
  text-align: center;
  margin-bottom: 25px;
  color: rgb(10, 88, 202);
  font-size: 2rem;
  font-weight: bold;
`;

const PERSONALITY_QUESTIONS = [
  'How do you handle conflict?',
  'How do you approach new challenges?',
  'How do you motivate others?',
  'How do you prefer to communicate?',
  'How do you handle a stressful situation?',
  'How do you prefer to work in a team?',
  'How do you approach tasks at work?',
  'How do you make decisions?',
  'How do you respond to criticism?',
  'What role do you prefer in a project?',
  'How do you react to tight deadlines?',
  'What motivates you the most at work?',
  'How do you handle change?',
  'How do you solve problems?',
  'What is your approach to learning new skills?',
];

const generatePersonalityReport = (answers, expertName, gender) => {
  const typeScores = { Dominance: 0, Influence: 0, Steadiness: 0, Conscientiousness: 0 };

  answers.forEach((answer, index) => {
    switch (index) {
      case 0:
      case 1:
        if (answer === 'I address it head-on and seek to resolve it quickly.' || answer === 'I take them on eagerly and push for quick results.') typeScores.Dominance += 1;
        if (answer === 'I try to find a compromise that works for everyone.' || answer === 'I collaborate with others to find the best solution.') typeScores.Influence += 1;
        if (answer === 'I prefer to avoid it and maintain harmony.' || answer === 'I proceed cautiously and maintain consistency.') typeScores.Steadiness += 1;
        if (answer === 'I analyze the situation before taking any action.' || answer === 'I thoroughly plan and consider all options before acting.') typeScores.Conscientiousness += 1;
        break;
      case 2:
      case 3:
        if (answer === 'By setting clear goals and expectations.' || answer === 'Directly and to the point.') typeScores.Dominance += 1;
        if (answer === 'By encouraging and inspiring them with enthusiasm.' || answer === 'With energy and excitement.') typeScores.Influence += 1;
        if (answer === 'By offering support and creating a stable environment.' || answer === 'Calmly and thoughtfully.') typeScores.Steadiness += 1;
        if (answer === 'By providing detailed instructions and guidance.' || answer === 'Clearly and with detailed information.') typeScores.Conscientiousness += 1;
        break;
      case 4:
      case 5:
        if (answer === 'I tackle it immediately and assertively.' || answer === 'As the leader, driving the team forward.') typeScores.Dominance += 1;
        if (answer === 'I stay positive and try to uplift others.' || answer === 'As a connector, bringing people together.') typeScores.Influence += 1;
        if (answer === 'I remain calm and look for steady solutions.' || answer === 'As a reliable team member, ensuring stability.') typeScores.Steadiness += 1;
        if (answer === 'I analyze the problem and develop a strategic plan.' || answer === 'As the detail-oriented one, ensuring quality.') typeScores.Conscientiousness += 1;
        break;
      case 6:
      case 7:
        if (answer === 'Quickly and efficiently, focusing on results.' || answer === 'Decisively and independently.') typeScores.Dominance += 1;
        if (answer === 'Enthusiastically, involving others.' || answer === 'By gathering input from others.') typeScores.Influence += 1;
        if (answer === 'Consistently, with a steady pace.' || answer === 'After considering everyone\'s feelings.') typeScores.Steadiness += 1;
        if (answer === 'Carefully, ensuring accuracy and quality.' || answer === 'Based on thorough analysis and data.') typeScores.Conscientiousness += 1;
        break;
      case 8:
      case 9:
        if (answer === 'I take it as a challenge to improve.' || answer === 'The one in charge, setting direction.') typeScores.Dominance += 1;
        if (answer === 'I discuss it with others to understand their perspective.' || answer === 'The one fostering team spirit and motivation.') typeScores.Influence += 1;
        if (answer === 'I reflect on it and try to see the positive side.' || answer === 'The one ensuring everyone is supported and tasks are completed.') typeScores.Steadiness += 1;
        if (answer === 'I analyze the feedback carefully and plan my improvements.' || answer === 'The one focusing on details and ensuring high standards.') typeScores.Conscientiousness += 1;
        break;
      case 10:
      case 11:
        if (answer === 'I push hard to get things done on time.' || answer === 'Achieving goals and getting results.') typeScores.Dominance += 1;
        if (answer === 'I stay positive and encourage the team to meet the deadline.' || answer === 'Building relationships and being recognized.') typeScores.Influence += 1;
        if (answer === 'I maintain a steady pace to avoid errors.' || answer === 'Stability and a supportive environment.') typeScores.Steadiness += 1;
        if (answer === 'I plan meticulously to meet the deadline without compromising quality.' || answer === 'Precision and high-quality work.') typeScores.Conscientiousness += 1;
        break;
      case 12:
      case 13:
        if (answer === 'I adapt quickly and take charge of new situations.' || answer === 'By making quick decisions and acting on them.') typeScores.Dominance += 1;
        if (answer === 'I embrace it enthusiastically and get others on board.' || answer === 'By brainstorming with others and finding creative solutions.') typeScores.Influence += 1;
        if (answer === 'I prefer gradual changes and maintaining consistency.' || answer === 'By staying calm and finding a steady approach.') typeScores.Steadiness += 1;
        if (answer === 'I analyze the changes carefully before adapting.' || answer === 'By thoroughly analyzing the problem and developing a detailed plan.') typeScores.Conscientiousness += 1;
        break;
      case 14:
        if (answer === 'I prefer learning through practical experience and challenges.') typeScores.Dominance += 1;
        if (answer === 'I enjoy interactive and social learning environments.') typeScores.Influence += 1;
        if (answer === 'I like structured and consistent learning processes.') typeScores.Steadiness += 1;
        if (answer === 'I focus on detailed and comprehensive learning materials.') typeScores.Conscientiousness += 1;
        break;
      default:
        break;
    }
  });

  const maxScore = Math.max(...Object.values(typeScores));
  const dominantType = Object.keys(typeScores).find((type) => typeScores[type] === maxScore);

  return {
    typeScores,
    dominantType,
    report: {
      name: expertName,
      strengths: ['Patience', 'Reliability', 'Team-Oriented', 'Consistency'],
      challenges: ['Resistance to Change', 'Over-Accommodating', 'Assertiveness'],
      suitableRoles: ['Customer service', 'Support roles', 'Positions requiring consistency and reliability', 'Team coordination roles'],
      tips: ['Embrace Stability', 'Support Others', 'Adapt Gradually', 'Assertive Communication'],
      pronoun: gender === 'male' ? 'he' : 'she',
      possessivePronoun: gender === 'male' ? 'his' : 'her',
    },
  };
};

const createPersonalityChart = (typeScores) => {
  const ctx = document.getElementById('personalityChart').getContext('2d');

  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ['Dominance', 'Influence', 'Steadiness', 'Conscientiousness'],
      datasets: [
        {
          label: 'Personality Scores',
          data: ['Dominance', 'Influence', 'Steadiness', 'Conscientiousness'].map((type) => typeScores[type]),
          backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe', '#ffce56'],
          borderRadius: 5,
        },
      ],
    },
    options: {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: '#ddd',
          },
        },
        x: {
          grid: {
            color: '#ddd',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  });
};

const PersonalityPDF = ({ personalityResults }) => (
  <Document>
    <Page style={pdfStyles.page}>
      <View style={pdfStyles.header}>
        <Text style={pdfStyles.title}>Personality Report</Text>
      </View>
      <View style={pdfStyles.section}>
        <Text style={pdfStyles.label}>Dominant Personality Type:</Text>
        <Text>{personalityResults.dominantType}</Text>
      </View>
      <View style={pdfStyles.section}>
        <Text style={pdfStyles.label}>Personality Scores:</Text>
        {['Dominance', 'Influence', 'Steadiness', 'Conscientiousness'].map((type) => (
          <Text key={type}>{`${type}: ${personalityResults.typeScores[type]}`}</Text>
        ))}
      </View>
      <View style={pdfStyles.section}>
        <Text style={pdfStyles.label}>
          Based on the responses, {personalityResults.report.name}'s predominant personality type is{' '}
          {personalityResults.dominantType}. This means {personalityResults.report.pronoun} is{' '}
          {personalityResults.report.strengths.join(', ')}. Let's explore more about {personalityResults.report.name}'s
          personality traits and how {personalityResults.report.pronoun} can leverage them for success.
        </Text>
        <Text style={pdfStyles.subTitle}>Key Strengths</Text>
        {personalityResults.report.strengths.map((strength, index) => (
          <Text key={index}>
            {`${strength}: ${personalityResults.report.name} ${
              strength === 'Patience' ? 'remains calm and composed, even in stressful situations.' : ''
            }`}
          </Text>
        ))}
        <Text style={pdfStyles.subTitle}>Potential Challenges</Text>
        {personalityResults.report.challenges.map((challenge, index) => (
          <Text key={index}>
            {`${challenge}: ${personalityResults.report.name} ${
              challenge === 'Resistance to Change' ? 'may prefer stability and find change challenging.' : ''
            }`}
          </Text>
        ))}
        <Text style={pdfStyles.subTitle}>Suitable Roles</Text>
        {personalityResults.report.suitableRoles.map((role, index) => (
          <Text key={index}>{role}</Text>
        ))}
        <Text style={pdfStyles.subTitle}>Tips for Leveraging Strengths</Text>
        {personalityResults.report.tips.map((tip, index) => (
          <Text key={index}>
            {`${tip}: ${personalityResults.report.name} ${
              tip === 'Embrace Stability'
                ? `can use ${personalityResults.report.possessivePronoun} preference for stability to create a reliable and consistent work environment.`
                : ''
            }`}
          </Text>
        ))}
        <Text style={pdfStyles.subTitle}>Personality Test Questions and Answers</Text>
        {personalityResults.answers.map((answer, index) => (
          <Text key={index}>{`${PERSONALITY_QUESTIONS[index]}: ${answer}`}</Text>
        ))}
      </View>
    </Page>
  </Document>
);

const pdfStyles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    textAlign: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
    marginTop: 10,
  },
});

const ApplicantsPage = () => {
  const [applicants, setApplicants] = useState([]);
  const [recentApplicants, setRecentApplicants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage] = useState(5);
  const [activeTab, setActiveTab] = useState('all');
  const [totalJobs, setTotalJobs] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [interviewDate, setInterviewDate] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [scheduledMeetups, setScheduledMeetups] = useState([]);
  const [rescheduleId, setRescheduleId] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [hireAmount, setHireAmount] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState('one-time');
  const [isHiring, setIsHiring] = useState(false);
  const [currentApplicant, setCurrentApplicant] = useState(null);
  const [clientCard, setClientCard] = useState(null);
  const [expertCard, setExpertCard] = useState(null);
  const [showCardPopup, setShowCardPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [contractDetails, setContractDetails] = useState({ clientName: '', expertName: '', terms: '' });
  const [contractUploaded, setContractUploaded] = useState(false);
  const [activeContracts, setActiveContracts] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [errorMessage, setErrorMessage] = useState('');
  const [deadline, setDeadline] = useState(null);
  const [reminder, setReminder] = useState(null);
  const [signature, setSignature] = useState('');
  const [signingDate, setSigningDate] = useState(null);
  const [signingTime, setSigningTime] = useState(null);
  const sigCanvasRef = useRef(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [selectedVideoURL, setSelectedVideoURL] = useState('');

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setAccessToken(tokenResponse.access_token);
    },
    onError: (error) => console.log('Login Failed:', error),
    scope: 'https://www.googleapis.com/auth/calendar.events',
  });

  const [showPersonalityPopup, setShowPersonalityPopup] = useState(false);
  const [personalityResults, setPersonalityResults] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserEmail(user.email);
        await fetchApplicants(user.email);
        await fetchTotalJobs(user.email);
        await fetchScheduledMeetups(user.email);
        await fetchClientCardInfo(user.email);
        await fetchActiveContracts(user.email);
        await fetchRecentApplicants(user.email);
      } else {
        navigate('/clientlogin');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchApplicants = async (userEmail) => {
    const jobQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const jobsSnapshot = await getDocs(jobQuery);
    const jobIds = jobsSnapshot.docs.map((doc) => doc.id);

    setTotalJobs(jobsSnapshot.size);

    if (jobIds.length > 0) {
      const applicantsQuery = query(collection(db, 'jobApplications'), where('jobID', 'in', jobIds));
      const applicantsSnapshot = await getDocs(applicantsQuery);
      const applicantsData = await Promise.all(
        applicantsSnapshot.docs.map(async (doc) => {
          const applicantData = doc.data();
          const profileQuery = query(collection(db, 'profiles'), where('email', '==', applicantData.email));
          const profileSnapshot = await getDocs(profileQuery);
          const profileImageUrl = profileSnapshot.docs.length > 0 ? profileSnapshot.docs[0].data().profileImage : null;
          const jobDetails = jobsSnapshot.docs.find((jobDoc) => jobDoc.id === doc.data().jobID)?.data() || {};
          const videoURLs = Object.entries(applicantData)
            .filter(([key]) => key.startsWith('videoURL'))
            .map(([, value]) => value);

          return {
            id: doc.id,
            ...applicantData,
            jobTitle: jobDetails.title || 'Unknown Job Title',
            jobDetails,
            profileImageUrl,
            videoURLs,
            priority: applicantData.priority || false,
          };
        })
      );

      setApplicants(applicantsData);
    } else {
      setApplicants([]);
    }
  };

  const fetchTotalJobs = async (userEmail) => {
    const jobsQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const jobsSnapshot = await getDocs(jobsQuery);
    setTotalJobs(jobsSnapshot.size);
  };

  const fetchScheduledMeetups = async (userEmail) => {
    const interviewsQuery = query(collection(db, 'interviews'), where('userEmail', '==', userEmail));
    const interviewsSnapshot = await getDocs(interviewsQuery);
    const interviewsData = interviewsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      interviewDate: doc.data().interviewDate.toDate(),
    }));

    setScheduledMeetups(interviewsData);
  };

  const fetchClientCardInfo = async (email) => {
    const clientQuery = query(collection(db, 'clientPayments'), where('email', '==', email));
    const clientSnapshot = await getDocs(clientQuery);
    if (clientSnapshot.docs.length > 0) {
      setClientCard(clientSnapshot.docs[0].data().cardDetails);
    }
  };

  const fetchExpertCardInfo = async (email) => {
    const expertQuery = query(collection(db, 'expertPayments'), where('email', '==', email));
    const expertSnapshot = await getDocs(expertQuery);
    if (expertSnapshot.docs.length > 0) {
      setExpertCard(expertSnapshot.docs[0].data().cardDetails);
    }
  };

  const fetchActiveContracts = async (userEmail) => {
    const contractsQuery = query(collection(db, 'contracts'), where('clientEmail', '==', userEmail));
    const contractsSnapshot = await getDocs(contractsQuery);
    const contractsData = contractsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setActiveContracts(contractsData);
  };

  const fetchRecentApplicants = async (userEmail) => {
    const jobQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const jobsSnapshot = await getDocs(jobQuery);
    const jobIds = jobsSnapshot.docs.map((doc) => doc.id);

    if (jobIds.length > 0) {
      const recentApplicantsQuery = query(
        collection(db, 'jobApplications'),
        where('jobID', 'in', jobIds),
        where('createdAt', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
      );
      const recentApplicantsSnapshot = await getDocs(recentApplicantsQuery);
      const recentApplicantsData = await Promise.all(
        recentApplicantsSnapshot.docs.map(async (doc) => {
          const applicantData = doc.data();
          const profileQuery = query(collection(db, 'profiles'), where('email', '==', applicantData.email));
          const profileSnapshot = await getDocs(profileQuery);
          const profileImageUrl = profileSnapshot.docs.length > 0 ? profileSnapshot.docs[0].data().profileImage : null;
          const jobDetails = jobsSnapshot.docs.find((jobDoc) => jobDoc.id === doc.data().jobID)?.data() || {};
          const videoURLs = Object.entries(applicantData)
            .filter(([key]) => key.startsWith('videoURL'))
            .map(([, value]) => value);

          return {
            id: doc.id,
            ...applicantData,
            jobTitle: jobDetails.title || 'Unknown Job Title',
            jobDetails,
            profileImageUrl,
            videoURLs,
            priority: applicantData.priority || false,
          };
        })
      );

      setRecentApplicants(recentApplicantsData);
    } else {
      setRecentApplicants([]);
    }
  };

  const handleShortlist = async (applicantId, applicantEmail) => {
    await updateApplicantStage(applicantId, 'shortlisted');
    await addNotification(applicantEmail, 'Your profile has been shortlisted.');
    setApplicants(applicants.map((app) => (app.id === applicantId ? { ...app, shortlisted: true } : app)));
    await addClientNotification(userEmail, `You have shortlisted ${applicantEmail}.`);
    toast.success('Applicant shortlisted successfully!');
  };

  const handleReject = async (applicantId, applicantEmail) => {
    await updateApplicantStage(applicantId, 'rejected');
    await addNotification(applicantEmail, 'Your application is currently under review.');
    setApplicants(applicants.map((app) => (app.id === applicantId ? { ...app, rejected: true } : app)));
    await addClientNotification(userEmail, `You have rejected ${applicantEmail}.`);
    toast.error('Applicant rejected successfully!');
  };

  const handleMoveToInterview = async (applicantId, applicantEmail, applicantName) => {
    await updateApplicantStage(applicantId, 'interviews');
    setApplicants(applicants.map((app) => (app.id === applicantId ? { ...app, shortlisted: false, interviews: true } : app)));
    await handleScheduleInterview(applicantId, applicantEmail, applicantName);
    await addNotification(applicantEmail, 'Successfully moved to interview stage.');
    await addClientNotification(userEmail, `You have moved ${applicantEmail} to the interview stage.`);
    toast.success('Applicant moved to interview stage successfully!');
  };

  const updateApplicantStage = async (applicantId, newStage) => {
    const applicantRef = doc(db, 'jobApplications', applicantId);
    await updateDoc(applicantRef, { stage: newStage });
  };

  const addNotification = async (email, message) => {
    await addDoc(collection(db, 'notifications'), {
      email,
      message,
      timestamp: new Date(),
      userId: auth.currentUser.uid,
    });
  };

  const addClientNotification = async (email, message) => {
    await addDoc(collection(db, 'notifications'), {
      email,
      message,
      timestamp: new Date(),
      userId: auth.currentUser.uid,
    });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handleScheduleInterview = async (applicantId, applicantEmail, applicantName) => {
    if (!interviewDate) {
      alert('Please select an interview date.');
      return;
    }

    if (!accessToken) {
      login();
      return;
    }

    try {
      const response = await createGoogleMeetEvent(interviewDate, accessToken);
      const meetingLink = response.data.hangoutLink;

      const interviewRef = await addDoc(collection(db, 'interviews'), {
        applicantId,
        applicantEmail,
        applicantName,
        jobTitle: applicants.find((app) => app.id === applicantId)?.jobTitle,
        interviewDate,
        meetingLink,
        userEmail,
      });

      await addNotification(applicantEmail, `Your interview is scheduled on ${interviewDate.toLocaleString()}. Meeting link: ${meetingLink}`);
      await addClientNotification(
        userEmail,
        `Interview with ${applicantName} (${applicantEmail}) is scheduled on ${interviewDate.toLocaleString()}. Meeting link: ${meetingLink}`
      );

      setScheduledMeetups([
        ...scheduledMeetups,
        { id: interviewRef.id, applicantId, applicantEmail, applicantName, jobTitle: applicants.find((app) => app.id === applicantId)?.jobTitle, interviewDate, meetingLink, userEmail },
      ]);
      setInterviewDate(null);
      toast.success('Interview scheduled successfully!');
    } catch (error) {
      console.error('Error creating Google Meet event:', error);
      alert('There was an error scheduling the interview. Please try again.');
    }
  };

  const createGoogleMeetEvent = (date, token) => {
    const event = {
      summary: 'Interview',
      start: { dateTime: date.toISOString(), timeZone: 'UTC' },
      end: {
        dateTime: new Date(date.getTime() + 30 * 60 * 1000).toISOString(),
        timeZone: 'UTC',
      },
      conferenceData: {
        createRequest: {
          requestId: 'sample123',
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
        },
      },
    };

    return axios.post('https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1', event, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const handleCancelInterview = async (interviewId, applicantEmail) => {
    try {
      const interview = scheduledMeetups.find((meetup) => meetup.id === interviewId);

      if (interview) {
        await deleteDoc(doc(db, 'interviews', interviewId));
        await addNotification(applicantEmail, `Your interview scheduled on ${interview.interviewDate.toLocaleString()} has been canceled.`);
        await addClientNotification(userEmail, `Interview with ${interview.applicantName} (${interview.applicantEmail}) scheduled on ${interview.interviewDate.toLocaleString()} has been canceled.`);

        setScheduledMeetups(scheduledMeetups.filter((meetup) => meetup.id !== interviewId));
        toast.success('Interview canceled successfully!');
      }
    } catch (error) {
      console.error('Error canceling interview:', error);
      alert('There was an error canceling the interview. Please try again.');
    }
  };

  const handleRescheduleInterview = async (interviewId, applicantEmail, applicantName) => {
    if (!interviewDate) {
      alert('Please select a new interview date.');
      return;
    }

    if (!accessToken) {
      login();
      return;
    }

    try {
      const response = await createGoogleMeetEvent(interviewDate, accessToken);
      const meetingLink = response.data.hangoutLink;

      const interviewRef = doc(db, 'interviews', interviewId);
      await updateDoc(interviewRef, {
        interviewDate,
        meetingLink,
      });

      await addNotification(applicantEmail, `Your interview has been rescheduled to ${interviewDate.toLocaleString()}. New meeting link: ${meetingLink}`);
      await addClientNotification(
        userEmail,
        `Interview with ${applicantName} (${applicantEmail}) has been rescheduled to ${interviewDate.toLocaleString()}. New meeting link: ${meetingLink}`
      );

      setScheduledMeetups(
        scheduledMeetups.map((meetup) =>
          meetup.id === interviewId ? { ...meetup, interviewDate, meetingLink } : meetup
        )
      );
      setInterviewDate(null);
      setRescheduleId(null);
      toast.success('Interview rescheduled successfully!');
    } catch (error) {
      console.error('Error rescheduling Google Meet event:', error);
      alert('There was an error rescheduling the interview. Please try again.');
    }
  };

  const handleHire = async (applicant) => {
    setIsHiring(true);
    setCurrentApplicant(applicant);
    await fetchExpertCardInfo(applicant.email);
    setContractDetails({
      clientName: userEmail,
      expertName: applicant.name,
      email: applicant.email,
      phone: applicant.phone,
    });
    setCurrentStep(1);
  };

  const handleSaveContractDetails = (e) => {
    const { name, value } = e.target;
    setContractDetails({ ...contractDetails, [name]: value });
  };

  const handleUploadContract = (e) => {
    const file = e.target.files[0];
    if (file) {
      setContractUploaded(true);
    }
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handlePaymentSuccess = async () => {
    try {
      await saveContractDetails();
      if (paymentMethod === 'stripe') {
        const paymentIntent = await createStripePaymentIntent(hireAmount);
        if (paymentIntent.status === 'succeeded') {
          setIsHiring(false);
          setCurrentApplicant(null);
          await addNotification(currentApplicant.email, 'You have been successfully hired.');
          await addClientNotification(userEmail, `You have successfully hired ${currentApplicant.email}.`);
          toast.success('Payment successful and applicant hired!');
        } else {
          setErrorMessage('Payment failed. Please try again.');
        }
      } else if (paymentMethod === 'escrow') {
        setIsHiring(false);
        setCurrentApplicant(null);
        await addNotification(currentApplicant.email, 'You have been successfully hired.');
        await addClientNotification(userEmail, `You have successfully hired ${currentApplicant.email}.`);
        toast.success('Payment successful and applicant hired!');
      }
    } catch (error) {
      setErrorMessage('Payment processing failed. Please try again.');
    }
  };

  const createStripePaymentIntent = async (amount) => {
    const response = await axios.post('/create-payment-intent', { amount });
    return response.data;
  };

  const saveContractDetails = async () => {
    await addDoc(collection(db, 'contracts'), {
      ...contractDetails,
      clientEmail: userEmail,
      expertEmail: currentApplicant.email,
      hireAmount,
      paymentFrequency,
      contractUploaded,
      createdAt: serverTimestamp(),
      deadline,
      reminder,
      expertSignature: signature,
      signingDate: signingDate ? new Date(signingDate) : new Date(),
      signingTime: signingTime ? new Date(signingTime) : new Date(),
    });
  };

  const handleSaveCardDetails = async (cardDetails, userType) => {
    if (userType === 'client') {
      await addDoc(collection(db, 'clientPayments'), { email: userEmail, cardDetails });
      setClientCard(cardDetails);
    } else if (userType === 'expert') {
      await addDoc(collection(db, 'expertPayments'), { email: currentApplicant.email, cardDetails });
      setExpertCard(cardDetails);
    }
    setShowCardPopup(false);
  };

  const handleEndContract = async (contractId) => {
    try {
      await deleteDoc(doc(db, 'contracts', contractId));
      setActiveContracts(activeContracts.filter((contract) => contract.id !== contractId));
      await addNotification(currentApplicant.email, 'Your contract has been ended.');
      await addClientNotification(userEmail, `You have ended the contract with ${currentApplicant.email}.`);
      toast.success('Contract ended successfully!');
    } catch (error) {
      console.error('Error ending contract:', error);
      alert('There was an error ending the contract. Please try again.');
    }
  };

  const handleMarkPriority = async (applicantId) => {
    const applicantRef = doc(db, 'jobApplications', applicantId);
    const updatedApplicants = applicants.map((app) => {
      if (app.id === applicantId) {
        updateDoc(applicantRef, { priority: !app.priority });
        return { ...app, priority: !app.priority };
      }
      return app;
    });
    setApplicants(updatedApplicants);
  };

  const filteredApplicants = applicants
    .filter((applicant) => {
      const jobTypeMatch = applicant.jobType?.toLowerCase().includes(searchTerm.toLowerCase());
      const coverLetterMatch = applicant.coverLetter?.toLowerCase().includes(searchTerm.toLowerCase());
      const nameMatch = applicant.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const jobTitleMatch = applicant.jobTitle?.toLowerCase().includes(searchTerm.toLowerCase());
      return jobTypeMatch || coverLetterMatch || nameMatch || jobTitleMatch;
    })
    .filter((applicant) => activeTab === 'all' || applicant[activeTab])
    .sort((a, b) => b.priority - a.priority);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredApplicants.length / applicantsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleShowPersonalityPopup = (applicant) => {
    if (!applicant.personalityAnswers || !applicant.personalityAnswers.length) {
      alert('This applicant has not completed the personality test.');
      return;
    }
    const gender = applicant.gender || 'male'; // Assuming gender data is available, defaulting to 'male' if not provided
    const results = generatePersonalityReport(applicant.personalityAnswers, applicant.name, gender);
    setPersonalityResults({ ...results, answers: applicant.personalityAnswers });
    setShowPersonalityPopup(true);
  };

  useEffect(() => {
    if (personalityResults) {
      createPersonalityChart(personalityResults.typeScores);
    }
  }, [personalityResults]);

  return (
    <>
      <ClientNavbar />
      <HeroSection>
        <HeroText>Welcome to the Applicants Page</HeroText>
        <HeroSubText>Here you can find all the talents ready to join and elevate your team.</HeroSubText>
      </HeroSection>
      <Container>
        <Sidebar>
          <SidebarButton onClick={() => navigate('/alljobs')}>
            View All Jobs <Badge>{totalJobs}</Badge>
          </SidebarButton>
          <SidebarButton onClick={() => setActiveTab('contracts')}>
            Active Contracts <Badge>{activeContracts.length}</Badge>
          </SidebarButton>
        </Sidebar>
        <Content>
          <Controls>
            <SearchInput
              type="text"
              placeholder="Search applicants"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <TabButton onClick={() => handleTabChange('all')} isActive={activeTab === 'all'}>
              All
            </TabButton>
            <TabButton onClick={() => handleTabChange('shortlisted')} isActive={activeTab === 'shortlisted'}>
              Shortlisted
            </TabButton>
            <TabButton onClick={() => handleTabChange('rejected')} isActive={activeTab === 'rejected'}>
              Rejected
            </TabButton>
            <TabButton onClick={() => handleTabChange('interviews')} isActive={activeTab === 'interviews'}>
              Interviews
            </TabButton>
          </Controls>
          <RecentApplications recentApplicants={recentApplicants} setSelectedVideoURL={setSelectedVideoURL} setShowVideoPopup={setShowVideoPopup} />

          {activeTab === 'contracts' ? (
            <ActiveContracts>
              <SectionTitle>Active Contracts</SectionTitle>
              {activeContracts.length > 0 ? (
                activeContracts.map((contract) => (
                  <ContractCard key={contract.id}>
                    <ContractDetails>
                      <DetailItem>
                        <strong>Client Name:</strong> {contract.clientName}
                      </DetailItem>
                      <DetailItem>
                        <strong>Expert Name:</strong> {contract.expertName}
                      </DetailItem>
                      <DetailItem>
                        <strong>Hire Amount:</strong> {contract.hireAmount}
                      </DetailItem>
                      <DetailItem>
                        <strong>Payment Frequency:</strong> {contract.paymentFrequency}
                      </DetailItem>
                      <DetailItem>
                        <strong>Terms:</strong> <ScrollableTerms>{contract.terms}</ScrollableTerms>
                      </DetailItem>
                      <DetailItem>
                        <strong>Contract Uploaded:</strong> {contract.contractUploaded ? 'Yes' : 'No'}
                      </DetailItem>
                      {contract.expertSignature && (
                        <PDFDownloadLink document={<ContractPDF contract={contract} />} fileName={`${contract.clientName}_Contract.pdf`}>
                          {({ loading }) => (
                            <ActionButton bgColor={loading ? '#ccc' : 'rgb(10, 88, 202)'}>
                              <FontAwesomeIcon icon={faDownload} /> {loading ? 'Loading...' : 'Download PDF'}
                            </ActionButton>
                          )}
                        </PDFDownloadLink>
                      )}
                      {contract.expertSignature && (
                        <DetailItem>
                          <PDFDownloadLink document={<ContractPDF contract={contract} />} fileName={`${contract.clientName}_Signed_Contract.pdf`}>
                            <ActionButton bgColor="rgb(10, 88, 202)">
                              <FontAwesomeIcon icon={faDownload} /> Download Signed Contract
                            </ActionButton>
                          </PDFDownloadLink>
                        </DetailItem>
                      )}
                      {!contract.expertSignature && <DetailItem style={{ color: 'red' }}>The Expert has not signed yet.</DetailItem>}
                      <ButtonContainer>
                        <ActionButton onClick={() => handleEndContract(contract.id)} bgColor="#ff6b6b">
                          <FontAwesomeIcon icon={faTimes} /> End Contract
                        </ActionButton>
                      </ButtonContainer>
                      <Label>Set Deadline:</Label>
                      <DatePicker selected={deadline} onChange={(date) => setDeadline(date)} showTimeSelect dateFormat="Pp" className="form-control" />
                      <Label>Set Reminder:</Label>
                      <DatePicker selected={reminder} onChange={(date) => setReminder(date)} showTimeSelect dateFormat="Pp" className="form-control" />
                    </ContractDetails>
                    <iframe
                      src={`https://docs.google.com/viewer?url=${contract.contractUrl}&embedded=true`}
                      width="100%"
                      height="400"
                      style={{ border: 'none', marginTop: '20px' }}
                    />
                  </ContractCard>
                ))
              ) : (
                <NoContracts>No active contracts found.</NoContracts>
              )}
            </ActiveContracts>
          ) : (
            <>
              {filteredApplicants.length > 0 ? (
                filteredApplicants.map((applicant) => (
                  <Card key={applicant.id}>
                    <CardHeader>
                      <JobTitle>{applicant.jobTitle}</JobTitle>
                      <Profile>
                        <ProfileImage src={applicant.profileImageUrl || 'https://via.placeholder.com/60'} alt="Profile" />
                        <ProfileDetails>
                          <Name>{applicant.name}</Name>
                          <ProfileText>
                            <FontAwesomeIcon icon={faEnvelope} /> {applicant.email}
                          </ProfileText>
                          <ProfileText>
                            <FontAwesomeIcon icon={faPhone} /> {applicant.phone}
                          </ProfileText>
                        </ProfileDetails>
                        {applicant.priority && <PriorityTag>Priority</PriorityTag>}
                      </Profile>
                    </CardHeader>
                    <SectionTitle>Job Details</SectionTitle>
                    <JobDetails>
                      <JobDetailItem>
                        <FontAwesomeIcon icon={faBriefcase} /> Type: {applicant.jobDetails?.type}
                      </JobDetailItem>
                      <JobDetailItem>
                        <FontAwesomeIcon icon={faBuilding} /> Industry: {applicant.jobDetails?.industry}
                      </JobDetailItem>
                      <JobDetailItem>
                        <FontAwesomeIcon icon={faInfoCircle} /> Description: {applicant.jobDetails?.description}
                      </JobDetailItem>
                      <JobDetailItem>
                        <FontAwesomeIcon icon={faClock} /> Expiry Date: {applicant.jobDetails?.expiryDate ? new Date(applicant.jobDetails.expiryDate.seconds * 1000).toLocaleDateString() : 'N/A'}
                      </JobDetailItem>
                      <JobDetailItem>
                        <FontAwesomeIcon icon={faInfoCircle} /> Skills: {applicant.jobDetails?.skills}
                      </JobDetailItem>
                    </JobDetails>
                    {applicant.videoURLs.length > 0 ? (
                      <VideoSection>
                        <label>Video Introduction:</label>
                        {applicant.videoURLs.map((url, index) => (
                          <div key={index}>
                            <QuestionText>Question {index + 1}</QuestionText>
                            <ActionButton bgColor="rgb(10, 88, 202)" onClick={() => { setSelectedVideoURL(url); setShowVideoPopup(true); }}>
                              <FontAwesomeIcon icon={faVideo} /> View Video
                            </ActionButton>
                          </div>
                        ))}
                      </VideoSection>
                    ) : (
                      <NoVideo>The Expert did not record a video for this role.</NoVideo>
                    )}
                    <ButtonContainer>
                      <ActionButton onClick={() => handleMarkPriority(applicant.id)} bgColor={applicant.priority ? '#ffd700' : 'rgb(10, 88, 202)'}>
                        <FontAwesomeIcon icon={faStar} /> {applicant.priority ? 'Unmark Priority' : 'Mark Priority'}
                      </ActionButton>
                      {applicant.shortlisted && (
                        <ActionButton onClick={() => handleMoveToInterview(applicant.id, applicant.email, applicant.name)} bgColor="rgb(10, 88, 202)">
                          <FontAwesomeIcon icon={faCalendar} /> Move to Interview
                        </ActionButton>
                      )}
                      <ActionButton onClick={() => handleShortlist(applicant.id, applicant.email)} bgColor="rgb(10, 88, 202)">
                        <FontAwesomeIcon icon={faCheck} /> Shortlist
                      </ActionButton>
                      <ActionButton onClick={() => navigate(`/candidate-profile/${applicant.email}`)} bgColor="rgb(10, 88, 202)">
                        <FontAwesomeIcon icon={faUser} /> View Profile
                      </ActionButton>
                      <ActionButton onClick={() => handleReject(applicant.id, applicant.email)} bgColor="#ff6b6b">
                        <FontAwesomeIcon icon={faTimes} /> Reject
                      </ActionButton>
                      <ActionButton onClick={() => handleShowPersonalityPopup(applicant)} bgColor="rgb(10, 88, 202)">
                        <FontAwesomeIcon icon={faInfoCircle} /> Personality Test
                      </ActionButton>
                      {activeTab === 'interviews' && (
                        <InterviewSection>
                          <label style={{ marginRight: '10px' }}>Interview Date:</label>
                          <DatePicker selected={interviewDate} onChange={(date) => setInterviewDate(date)} showTimeSelect dateFormat="Pp" className="form-control" />
                          <ActionButton onClick={() => handleScheduleInterview(applicant.id, applicant.email, applicant.name)} bgColor="rgb(10, 88, 202)">
                            <FontAwesomeIcon icon={faCalendar} /> Schedule Interview
                          </ActionButton>
                        </InterviewSection>
                      )}
                      <ActionButton onClick={() => handleHire(applicant)} bgColor="#28a745">
                        <FontAwesomeIcon icon={faDollarSign} /> Hire
                      </ActionButton>
                    </ButtonContainer>
                  </Card>
                ))
              ) : (
                <NoApplicants>No applicants found.</NoApplicants>
              )}
              <Pagination>
                {pageNumbers.map((number) => (
                  <PageNumber key={number} onClick={() => paginate(number)} isActive={currentPage === number}>
                    {number}
                  </PageNumber>
                ))}
              </Pagination>
            </>
          )}
        </Content>
      </Container>
      {activeTab === 'interviews' && (
        <Interviews>
          <InterviewHeader>Scheduled Interviews</InterviewHeader>
          <NoticeText>All interviews are conducted via Google Meet.</NoticeText>
          <InterviewTable>
            <thead>
              <tr>
                <InterviewTableHeader>Applicant Email</InterviewTableHeader>
                <InterviewTableHeader>Applicant Name</InterviewTableHeader>
                <InterviewTableHeader>Job Title</InterviewTableHeader>
                <InterviewTableHeader>Interview Date</InterviewTableHeader>
                <InterviewTableHeader>Meeting Link</InterviewTableHeader>
                <InterviewTableHeader>Actions</InterviewTableHeader>
              </tr>
            </thead>
            <tbody>
              {scheduledMeetups.map((meetup) => (
                <InterviewTableRow key={meetup.id}>
                  <InterviewTableCell>{meetup.applicantEmail}</InterviewTableCell>
                  <InterviewTableCell>{meetup.applicantName}</InterviewTableCell>
                  <InterviewTableCell>{meetup.jobTitle}</InterviewTableCell>
                  <InterviewTableCell>{meetup.interviewDate.toLocaleString()}</InterviewTableCell>
                  <InterviewTableCell>
                    <a href={meetup.meetingLink} target="_blank" rel="noopener noreferrer">
                      Join Meeting
                    </a>
                  </InterviewTableCell>
                  <InterviewTableCell>
                    <ActionButton onClick={() => setRescheduleId(meetup.id)} bgColor="#ffc107">
                      <FontAwesomeIcon icon={faEdit} /> Reschedule
                    </ActionButton>
                    <ActionButton onClick={() => handleCancelInterview(meetup.id, meetup.applicantEmail)} bgColor="#ff6b6b">
                      <FontAwesomeIcon icon={faTimes} /> Cancel
                    </ActionButton>
                    {rescheduleId === meetup.id && (
                      <div style={{ marginTop: '10px' }}>
                        <DatePicker selected={interviewDate} onChange={(date) => setInterviewDate(date)} showTimeSelect dateFormat="Pp" className="form-control" />
                        <ActionButton onClick={() => handleRescheduleInterview(meetup.id, meetup.applicantEmail, meetup.applicantName)} bgColor="rgb(10, 88, 202)">
                          <FontAwesomeIcon icon={faCalendar} /> Confirm Reschedule
                        </ActionButton>
                      </div>
                    )}
                  </InterviewTableCell>
                </InterviewTableRow>
              ))}
            </tbody>
          </InterviewTable>
        </Interviews>
      )}
      {isHiring && currentApplicant && (
        <StyledPopupOverlay>
          <StyledPopupContainer>
            <StyledCloseButton onClick={() => setIsHiring(false)}>&times;</StyledCloseButton>
            <StyledPopupTitle>Hire {currentApplicant.name}</StyledPopupTitle>
            <StyledPopupDescription>Please fill out the details below to complete the hiring process.</StyledPopupDescription>
            <StyledFeatureList>
              <StyledFeatureItem>
                <StyledFeatureIcon icon={faCheck} />
                {currentStep === 1 && (
                  <>
                    <StyledLabel>Client Name:</StyledLabel>
                    <StyledInputContainer>
                      <StyledInput type="text" name="clientName" value={contractDetails.clientName} onChange={handleSaveContractDetails} />
                    </StyledInputContainer>
                    <StyledLabel>Expert Name:</StyledLabel>
                    <StyledInputContainer>
                      <StyledInput type="text" name="expertName" value={contractDetails.expertName} onChange={handleSaveContractDetails} />
                    </StyledInputContainer>
                    <StyledLabel>Terms:</StyledLabel>
                    <StyledInputContainer>
                      <StyledTextarea name="terms" value={contractDetails.terms} onChange={handleSaveContractDetails}></StyledTextarea>
                    </StyledInputContainer>
                    <StyledButtonContainer>
                      <StyledActionButton onClick={handleNextStep} bgColor="rgb(10, 88, 202)">
                        Next
                      </StyledActionButton>
                    </StyledButtonContainer>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <StyledLabel>Upload Contract:</StyledLabel>
                    <StyledInputContainer>
                      <StyledInput type="file" onChange={handleUploadContract} />
                    </StyledInputContainer>
                    <StyledButtonContainer>
                      <StyledActionButton onClick={handlePreviousStep} bgColor="#6c757d">
                        Back
                      </StyledActionButton>
                      <StyledActionButton onClick={handleNextStep} bgColor="rgb(10, 88, 202)">
                        Next
                      </StyledActionButton>
                    </StyledButtonContainer>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    <StyledLabel>Hire Amount:</StyledLabel>
                    <StyledInputContainer>
                      <StyledInput type="text" value={hireAmount} onChange={(e) => setHireAmount(e.target.value)} />
                    </StyledInputContainer>
                    <StyledLabel>Payment Frequency:</StyledLabel>
                    <StyledInputContainer>
                      <StyledSelect value={paymentFrequency} onChange={(e) => setPaymentFrequency(e.target.value)}>
                        <option value="one-time">One-Time</option>
                        <option value="monthly">Monthly</option>
                        <option value="weekly">Weekly</option>
                      </StyledSelect>
                    </StyledInputContainer>
                    <StyledLabel>Payment Method:</StyledLabel>
                    <StyledInputContainer>
                      <StyledSelect value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                        <option value="stripe">Stripe</option>
                        <option value="escrow">Escrow</option>
                      </StyledSelect>
                    </StyledInputContainer>
                    <StyledLabel>Sign Contract:</StyledLabel>
                    <StyledInputContainer>
                      <SignatureCanvas
                        ref={sigCanvasRef}
                        penColor="black"
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                        onEnd={() => setSignature(sigCanvasRef.current.toDataURL())}
                      />
                    </StyledInputContainer>
                    <StyledLabel>Signing Date:</StyledLabel>
                    <StyledInputContainer>
                      <StyledInput type="date" onChange={(e) => setSigningDate(e.target.value)} />
                    </StyledInputContainer>
                    <StyledLabel>Signing Time:</StyledLabel>
                    <StyledInputContainer>
                      <StyledInput type="time" onChange={(e) => setSigningTime(e.target.value)} />
                    </StyledInputContainer>
                    <StyledButtonContainer>
                      <StyledActionButton onClick={handlePreviousStep} bgColor="#6c757d">
                        Back
                      </StyledActionButton>
                      <StyledActionButton onClick={handlePaymentSuccess} bgColor="rgb(10, 88, 202)">
                        Complete
                      </StyledActionButton>
                    </StyledButtonContainer>
                    {errorMessage && <StyledMessage>{errorMessage}</StyledMessage>}
                  </>
                )}
              </StyledFeatureItem>
            </StyledFeatureList>
          </StyledPopupContainer>
        </StyledPopupOverlay>
      )}
      {showPersonalityPopup && personalityResults && (
        <LandscapePopupOverlay>
          <LandscapePopupContainer>
            <LandscapeCloseButton onClick={() => setShowPersonalityPopup(false)}>&times;</LandscapeCloseButton>
            <LandscapePopupTitle>Personality Analysis</LandscapePopupTitle>
            <LandscapePopupDescription>Here are the detailed results of the personality test for the selected candidate.</LandscapePopupDescription>
            <LandscapeSectionTitle>Summary</LandscapeSectionTitle>
            <p>
              <strong>Dominant Personality Type:</strong> {personalityResults.dominantType}
            </p>
            <LandscapeSectionTitle>Personality Scores</LandscapeSectionTitle>
            <canvas id="personalityChart" width="400" height="200"></canvas>
            <LandscapeSectionTitle>Report</LandscapeSectionTitle>
            <p>
              Based on the responses, {personalityResults.report.name}'s predominant personality type is {personalityResults.dominantType}. This means {personalityResults.report.pronoun} is patient, reliable, and excels at maintaining harmony. Let's explore more about {personalityResults.report.name}'s personality traits and how {personalityResults.report.pronoun} can leverage them for success.
            </p>
            <LandscapeSectionTitle>Key Strengths</LandscapeSectionTitle>
            <p>Patience: {personalityResults.report.name} remains calm and composed, even in stressful situations.</p>
            <p>Reliability: {personalityResults.report.name} is dependable and can be counted on to follow through on commitments.</p>
            <p>Team-Oriented: {personalityResults.report.name} works well in teams, supporting and guiding others.</p>
            <p>Consistency: {personalityResults.report.name} maintains a steady and consistent approach in {personalityResults.report.pronoun} work.</p>
            <LandscapeSectionTitle>Potential Challenges</LandscapeSectionTitle>
            <p>Resistance to Change: {personalityResults.report.name} may prefer stability and find change challenging.</p>
            <p>Over-Accommodating: {personalityResults.report.name} might be overly accommodating, sometimes at the expense of {personalityResults.report.pronoun} own needs.</p>
            <p>Assertiveness: Being assertive can be a challenge for {personalityResults.report.name}, as {personalityResults.report.pronoun} prioritizes harmony.</p>
            <LandscapeSectionTitle>Suitable Roles</LandscapeSectionTitle>
            <p>Customer service</p>
            <p>Support roles</p>
            <p>Positions requiring consistency and reliability</p>
            <p>Team coordination roles</p>
            <LandscapeSectionTitle>Tips for Leveraging Strengths</LandscapeSectionTitle>
            <p>Embrace Stability: Use {personalityResults.report.name}'s preference for stability to create a reliable and consistent work environment.</p>
            <p>Support Others: Leverage {personalityResults.report.name}'s team-oriented nature to support and mentor colleagues.</p>
            <p>Adapt Gradually: Help {personalityResults.report.name} embrace change by implementing it gradually and steadily.</p>
            <p>Assertive Communication: Encourage {personalityResults.report.name} to practice assertive communication to ensure {personalityResults.report.possessivePronoun} needs are also met.</p>
            <PDFDownloadLink document={<PersonalityPDF personalityResults={personalityResults} />} fileName="Personality_Report.pdf">
              {({ loading }) => (
                <LandscapeActionButton bgColor="rgb(10, 88, 202)">
                  <FontAwesomeIcon icon={faDownload} /> {loading ? 'Loading...' : 'Download Personality Report'}
                </LandscapeActionButton>
              )}
            </PDFDownloadLink>
          </LandscapePopupContainer>
        </LandscapePopupOverlay>
      )}
      {showVideoPopup && (
        <PopupOverlay>
          <PopupContainer>
            <CloseButton onClick={() => setShowVideoPopup(false)}>&times;</CloseButton>
            <PopupTitle>Video Introduction</PopupTitle>
            <video controls style={{ width: '100%', maxHeight: '500px', borderRadius: '8px' }}>
              <source src={selectedVideoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </PopupContainer>
        </PopupOverlay>
      )}
      <Toaster />
    </>
  );
};

const RecentApplications = ({ recentApplicants, setSelectedVideoURL, setShowVideoPopup }) => {
  return (
    <Section>
      <SectionTitle>Recent Applications</SectionTitle>
      {recentApplicants.length > 0 ? (
        recentApplicants.map((applicant) => (
          <Card key={applicant.id}>
            <CardHeader>
              <JobTitle>{applicant.jobTitle}</JobTitle>
              <Profile>
                <ProfileImage src={applicant.profileImageUrl || 'https://via.placeholder.com/60'} alt="Profile" />
                <ProfileDetails>
                  <Name>{applicant.name}</Name>
                  <ProfileText>
                    <FontAwesomeIcon icon={faEnvelope} /> {applicant.email}
                  </ProfileText>
                  <ProfileText>
                    <FontAwesomeIcon icon={faPhone} /> {applicant.phone}
                  </ProfileText>
                </ProfileDetails>
                {applicant.priority && <PriorityTag>Priority</PriorityTag>}
              </Profile>
            </CardHeader>
            <SectionTitle>Job Details</SectionTitle>
            <JobDetails>
              <JobDetailItem>
                <FontAwesomeIcon icon={faBriefcase} /> Type: {applicant.jobDetails?.type}
              </JobDetailItem>
              <JobDetailItem>
                <FontAwesomeIcon icon={faBuilding} /> Industry: {applicant.jobDetails?.industry}
              </JobDetailItem>
              <JobDetailItem>
                <FontAwesomeIcon icon={faInfoCircle} /> Description: {applicant.jobDetails?.description}
              </JobDetailItem>
              <JobDetailItem>
                <FontAwesomeIcon icon={faClock} /> Expiry Date: {applicant.jobDetails?.expiryDate ? new Date(applicant.jobDetails.expiryDate.seconds * 1000).toLocaleDateString() : 'N/A'}
              </JobDetailItem>
              <JobDetailItem>
                <FontAwesomeIcon icon={faInfoCircle} /> Skills: {applicant.jobDetails?.skills}
              </JobDetailItem>
            </JobDetails>
            {applicant.videoURLs.length > 0 ? (
              <VideoSection>
                <label>Video Introduction:</label>
                {applicant.videoURLs.map((url, index) => (
                  <div key={index}>
                    <QuestionText>Question {index + 1}</QuestionText>
                    <ActionButton bgColor="rgb(10, 88, 202)" onClick={() => { setSelectedVideoURL(url); setShowVideoPopup(true); }}>
                      <FontAwesomeIcon icon={faVideo} /> View Video
                    </ActionButton>
                  </div>
                ))}
              </VideoSection>
            ) : (
              <NoVideo>The Expert did not record a video for this role.</NoVideo>
            )}
          </Card>
        ))
      ) : (
        <NoApplicants>No recent applications found.</NoApplicants>
      )}
    </Section>
  );
};

const HeroSection = styled.div`
  background-image: url(${heroImage});
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  text-align: center;
  color: white;
  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`;

const HeroText = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroSubText = styled.p`
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Sidebar = styled.div`
  flex: 1;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const SidebarButton = styled.button`
  display: flex;
  align-items: center;
  background: rgb(10, 88, 202);
  color: white;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const Badge = styled.span`
  background-color: white;
  color: rgb(10, 88, 202);
  border-radius: 50%;
  padding: 3px 8px;
  margin-left: 10px;
  font-size: 0.9rem;
`;

const Content = styled.div`
  flex: 3;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const TabButton = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? 'rgb(10, 88, 202)' : '#f1f1f1')};
  color: ${(props) => (props.isActive ? 'white' : 'black')};
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#0056b3' : '#ddd')};
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 0.9rem;
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
`;

const JobTitle = styled.h2`
  flex: 2;
  font-size: 1.5rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h3`
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 5px;
  }
`;

const ProfileText = styled.p`
  margin: 0;
  font-size: 0.9rem;
`;

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const JobDetailItem = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-size: 0.9rem;
`;

const VideoSection = styled.div`
  margin: 20px 0;

  @media (max-width: 768px) {
    margin: 15px 0;
  }
`;

const QuestionText = styled.p`
  margin: 0;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const NoVideo = styled.p`
  color: red;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ActionButton = styled.button`
  background-color: ${(props) => props.bgColor || 'rgb(10, 88, 202)'};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;

  &:hover {
    background-color: ${(props) => props.hoverColor || '#0056b3'};
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const PriorityTag = styled.span`
  background-color: #ffd700;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PageNumber = styled.button`
  background-color: ${(props) => (props.isActive ? 'rgb(10, 88, 202)' : '#f1f1f1')};
  color: ${(props) => (props.isActive ? 'white' : 'black')};
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#0056b3' : '#ddd')};
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 0.9rem;
    margin: 5px 0;
  }
`;

const Interviews = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
    margin: 15px;
  }
`;

const InterviewHeader = styled.h2`
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const NoticeText = styled.p`
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-bottom: 15px;
  }
`;

const InterviewTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const InterviewTableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f1f1f1;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.9rem;
  }
`;

const InterviewTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const InterviewTableCell = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 0.9rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.8rem;
  }
`;

const ActiveContracts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const ContractCard = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ContractDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ScrollableTerms = styled.div`
  max-height: 100px;
  overflow-y: auto;
`;

const InterviewSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledPopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  max-width: 500px;
  overflow-y: auto;
  max-height: 90vh;

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 95%;
  }
`;

const StyledCloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const StyledPopupTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const StyledPopupDescription = styled.p`
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
`;

const StyledFeatureList = styled.ul`
  list-style: none;
  padding: 0;
`;

const StyledFeatureItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const StyledFeatureIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 8px;
  }
`;

const StyledLabel = styled(Label)`
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 3px;
  }
`;

const StyledInputContainer = styled.div`
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.9rem;
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.9rem;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.9rem;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledActionButton = styled(ActionButton)`
  flex: 1;
  margin: 0 5px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const StyledMessage = styled.p`
  text-align: center;
  color: red;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const LandscapePopupOverlay = styled(PopupOverlay)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LandscapePopupContainer = styled(PopupContainer)`
  width: 90%;
  height: 90%;
  max-width: none;
  max-height: none;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 15px;
  }
`;

const LandscapeCloseButton = styled(CloseButton)`
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const LandscapePopupTitle = styled(PopupTitle)`
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const LandscapePopupDescription = styled.p`
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
`;

const LandscapeSectionTitle = styled.h3`
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 8px;
  }
`;

const LandscapeActionButton = styled(ActionButton)`
  display: block;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;

const NoContracts = styled.p`
  color: red;
  text-align: center;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const NoApplicants = styled.p`
  color: red;
  text-align: center;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Section = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }
`;

const ApplicantsPageComponent = () => (
  <GoogleOAuthProvider clientId="670669559995-91vhb1856fk239ff8bg2gd5km8b0r812.apps.googleusercontent.com">
    <ApplicantsPage />
  </GoogleOAuthProvider>
);

export default ApplicantsPageComponent;
