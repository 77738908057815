import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { auth, googleAuthProvider, db } from '../firebase';
import Modal from 'react-modal';
import styled from 'styled-components';
import { doc, getDoc, setDoc } from 'firebase/firestore';

Modal.setAppElement('#root');

// Utility function to extract the user's full name from the display name
const extractUserName = (firebaseUser) => {
    return firebaseUser.displayName ? firebaseUser.displayName : 'User';
};

const ExpertLoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');  // State for error handling
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                const userData = {
                    uid: firebaseUser.uid,
                    email: firebaseUser.email,
                    displayName: extractUserName(firebaseUser),
                    photoURL: firebaseUser.photoURL,
                };
                setUser(userData);
                await checkUserSubscription(userData);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    // Function to check user subscription
    const checkUserSubscription = async (userData) => {
        try {
            const userRef = doc(db, 'expertpricing', userData.uid);
            const docSnap = await getDoc(userRef);

            if (docSnap.exists() && docSnap.data().token) {
                console.log('User has an existing subscription token.');
                navigate('/expertdashboard', { state: { user: { ...userData, subscribed: true } } });
            } else {
                console.log('No existing subscription token found. Redirecting to pricing.');
                navigate('/expertpricing', { state: { user: userData } });
            }
        } catch (err) {
            setError('Error checking subscription: ' + err.message);
        }
    };

    // Function to handle email login
    const handleLoginWithEmail = async () => {
        if (!termsAccepted) {
            alert('You must accept the terms and conditions to continue.');
            return;
        }
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const { user } = userCredential;
            await checkUserSubscription({
                uid: user.uid,
                email: user.email,
                displayName: extractUserName(user),
                photoURL: user.photoURL,
            });
        } catch (error) {
            setError('Failed to log in with email: ' + error.message);
        }
    };

    // Function to handle Google login
    const handleLoginWithGoogle = async () => {
        if (!termsAccepted) {
            alert('You must accept the terms and conditions to continue.');
            return;
        }
        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const { user } = result;
            const userData = {
                uid: user.uid,
                email: user.email,
                displayName: extractUserName(user),
                photoURL: user.photoURL,
            };
            await checkUserSubscription(userData);
        } catch (error) {
            setError('Failed to log in with Google: ' + error.message);
        }
    };

    // Function to handle password reset
    const handleResetPassword = () => {
        navigate('/reset-password');
    };

    // Function to toggle the modal for terms and conditions
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    return (
        <Container>
            <FormSide>
                <FormContainer>
                    <Title>Welcome Back, Expert!</Title>
                    <Description>Enter your details to log in to ExpertsToo as an Expert.</Description>
                    <StyledInput 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                    />
                    <StyledInput 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                    <CheckboxContainer>
                        <label>
                            <Checkbox 
                                type="checkbox" 
                                checked={termsAccepted} 
                                onChange={() => setTermsAccepted(!termsAccepted)} 
                            />
                            Accept Terms and Conditions
                        </label>
                        <TermsLink onClick={toggleModal}>View Terms</TermsLink>
                    </CheckboxContainer>
                    <StyledButton onClick={handleLoginWithEmail}>Log In</StyledButton>
                    <StyledButton onClick={handleLoginWithGoogle} style={{ backgroundColor: '#db4437' }}>Continue with Google</StyledButton>
                    <LinkText onClick={handleResetPassword}>Forgot password?</LinkText>
                    <LinkText onClick={() => navigate('/expertsregister')}>Don't have an account? Sign up</LinkText>
                    {error && <ErrorText>{error}</ErrorText>} {/* Display any error messages */}
                </FormContainer>
            </FormSide>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Terms and Conditions"
                style={modalStyle}>
                <ModalTitle>Terms and Conditions</ModalTitle>
                <ModalContent>Here is a sample of terms and conditions. Please read and accept to continue.</ModalContent>
                <StyledButton onClick={toggleModal} style={{ backgroundColor: '#007bff' }}>Close</StyledButton>
            </Modal>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
`;

const FormSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 450px;
`;

const FormContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
`;

const Title = styled.h2`
  font-family: 'Open Sans', sans-serif;
  color: #333;
  margin-bottom: 10px;
  animation: slideInFromLeft 1s ease-in-out;
`;

const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  color: #666;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const TermsLink = styled.span`
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px 0;
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
`;

const LinkText = styled.span`
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
  margin-top: 10px;
`;

const ModalTitle = styled.h2`
  font-family: 'Open Sans', sans-serif;
  color: #333;
  margin-bottom: 10px;
`;

const ModalContent = styled.p`
  font-family: 'Open Sans', sans-serif;
  color: #666;
  margin-bottom: 20px;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        borderRadius: '10px',
        padding: '20px'
    }
};

export default ExpertLoginScreen;
