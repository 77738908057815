import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../firebase';
import Navbar from '../components/navbar';
import ServicesTwo from '../components/sercicesTwo';
import AboutUs from '../components/aboutUs';
import Blog from '../components/blog';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import { FiBookmark } from 'react-icons/fi';
import {
  BsBriefcase,
  BsCalendar,
  BsGeoAlt,
  BsPeople,
  BsFillPersonFill,
} from 'react-icons/bs';

const categories = [
  'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
  'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
  'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
  'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
];

const IndexTwo = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [currentCandidateIndex, setCurrentCandidateIndex] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [salaryFilter, setSalaryFilter] = useState('');
  const [experienceFilter, setExperienceFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 6;
  const [user] = useAuthState(auth);
  const db = getFirestore();

  useEffect(() => {
    const fetchJobs = async () => {
      const jobsCollection = collection(db, 'jobs');
      const jobSnapshot = await getDocs(jobsCollection);
      const jobList = jobSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(job => job.logo && Object.keys(job).length > 4)
        .sort((a, b) => Object.keys(b).length - Object.keys(a).length);
      setJobs(jobList);
      setFilteredJobs(jobList);
    };

    const fetchCandidates = async () => {
      const candidatesCollection = collection(db, 'profiles');
      const candidateSnapshot = await getDocs(candidatesCollection);
      const candidateList = candidateSnapshot.docs.map(doc => {
        const profile = doc.data();
        return {
          id: doc.id,
          name: profile.name,
          currentJobTitle: profile.currentJobTitle,
          imageUrl: profile.profileImage || user?.photoURL || 'path/to/default/profile.png',
          email: profile.email,
          workExperience: profile.workExperience,
        };
      });
      setCandidates(candidateList);
    };

    fetchJobs();
    fetchCandidates();
  }, [user]);

  useEffect(() => {
    if (candidates.length > 0) {
      const interval = setInterval(() => {
        setCurrentCandidateIndex(prevIndex => (prevIndex + 1) % candidates.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [candidates.length]);

  const applyForJob = job => {
    navigate('/vacancies');
  };

  const saveJob = jobId => {
    console.log(`Job ${jobId} saved`);
  };

  const truncateDescription = (description, maxLength) => {
    if (!description) return '';
    if (description.length <= maxLength) return description;
    return description.slice(0, maxLength) + '...';
  };

  const handleFilterChange = () => {
    let filtered = jobs;
    if (categoryFilter) {
      filtered = filtered.filter(job => job.category === categoryFilter);
    }
    if (locationFilter) {
      filtered = filtered.filter(job => job.location === locationFilter);
    }
    if (typeFilter) {
      filtered = filtered.filter(job => job.type === typeFilter);
    }
    if (salaryFilter) {
      filtered = filtered.filter(job => job.salaryRange === salaryFilter);
    }
    if (experienceFilter) {
      filtered = filtered.filter(job => job.experienceRange === experienceFilter);
    }
    setFilteredJobs(filtered);
  };

  useEffect(() => {
    handleFilterChange();
  }, [categoryFilter, locationFilter, typeFilter, salaryFilter, experienceFilter]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const scrollToJobsSection = () => {
    const jobsSection = document.getElementById('jobs-section');
    if (jobsSection) {
      jobsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCategoryClick = (category) => {
    setCategoryFilter(category);
    scrollToJobsSection();
  };

  const ExpertCard = ({ candidate }) => {
    if (!candidate) {
      return null;
    }

    return (
      <div style={styles.expertCard} className="expert-card">
        <div className="expert-image" style={styles.expertImage(candidate.imageUrl)} />
        <div style={styles.expertCardContent}>
          <h5 style={styles.expertCardTitle}>{candidate.name}</h5>
          <p style={styles.expertCardDetail}>
            <BsFillPersonFill style={styles.icon} /> Verified Expert in {candidate.currentJobTitle}
          </p>
          <p style={styles.expertCardDetail}>
            <BsBriefcase style={styles.icon} /> {candidate.currentJobTitle}
          </p>
          <p style={styles.expertCardDetail}>
            <BsGeoAlt style={styles.icon} /> Previously at <strong>Top Company</strong>
          </p>
          <a href={`/roleform`} style={styles.viewProfileButton}>
            View Profile
          </a>
        </div>
        <style jsx>{`
          .expert-card:hover {
            transform: scale(1.03);
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
          }
          .expert-card:hover .expert-image {
            transform: scale(1.2);
          }
          .expert-card:hover h5,
          .expert-card:hover p {
            color: rgb(10, 88, 202);
          }
          .expert-card:hover a {
            background-color: rgb(10, 88, 202);
            color: #fff;
          }
          @media (max-width: 768px) {
            .expert-card {
              display: none;
            }
          }
        `}</style>
      </div>
    );
  };

  const JobCard = ({ job }) => {
    return (
      <div className="job-card" key={job.id} style={styles.jobCard}>
        <img src={job.logo} alt="Company Logo" style={styles.jobCardLogo} />
        <div style={styles.jobCardContent}>
          <h5 style={styles.jobCardTitle}>{job.title}</h5>
          <p style={styles.jobCardDetail}>
            <BsGeoAlt style={styles.icon} /> {job.location}
          </p>
          <p style={styles.jobCardDetail}>
            <BsBriefcase style={styles.icon} /> {job.type} - {job.experience}
          </p>
          <p style={styles.jobCardDetail}>
            <BsCalendar style={styles.icon} /> {job.postedDate}
          </p>
          <p style={styles.jobCardDescription}>{truncateDescription(job.description, 100)}</p>
          <div style={styles.jobCardActions}>
            <p style={styles.salary}>{job.salary}</p>
            <button style={styles.applyButton} onClick={() => applyForJob(job)}>
              Apply Now
            </button>
            <button style={styles.saveButton} onClick={() => saveJob(job.id)}>
              <FiBookmark className="icons" />
            </button>
          </div>
        </div>
        <style jsx>{`
          .job-card:hover {
            transform: scale(1.03);
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
          }
          .job-card:hover img {
            transform: scale(1.1);
          }
          .job-card:hover h5,
          .job-card:hover p {
            color: rgb(10, 88, 202);
          }
          .job-card:hover button {
            background-color: rgb(10, 88, 202);
            color: #fff;
            transform: scale(1.05);
          }
          @media (max-width: 768px) {
            .job-card img {
              display: none;
            }
          }
        `}</style>
      </div>
    );
  };

  const styles = {
    expertCard: {
      width: '100%',
      maxWidth: '400px',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '15px',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
      textAlign: 'left',
      position: 'relative',
      margin: '20px',
      fontFamily: "'Poppins', sans-serif",
      transition: 'transform 0.3s, box-shadow 0.3s'
    },
    expertImage: (url) => ({
      width: '120px',
      height: '180px',
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '15px',
      border: '5px solid #fff',
      boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
      position: 'absolute',
      top: '-50px',
      left: '-50px',
      transition: 'transform 0.3s',
      zIndex: 1
    }),
    expertCardContent: {
      marginLeft: '80px',
      paddingTop: '130px'
    },
    expertCardTitle: {
      fontSize: '1.4em',
      fontWeight: '600',
      marginBottom: '10px',
      color: '#333',
      transition: 'color 0.3s'
    },
    expertCardDetail: {
      fontSize: '1em',
      color: '#555',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      transition: 'color 0.3s'
    },
    viewProfileButton: {
      display: 'inline-block',
      padding: '10px 20px',
      backgroundColor: '#fff',
      color: 'rgb(10, 88, 202)',
      borderRadius: '20px',
      textDecoration: 'none',
      fontSize: '1em',
      marginTop: '10px',
      transition: 'background-color 0.3s, color 0.3s'
    },
    icon: {
      marginRight: '8px'
    },
    jobCard: {
      border: '1px solid #ddd',
      borderRadius: '15px',
      padding: '20px',
      marginBottom: '20px',
      backgroundColor: '#fff',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.3s, box-shadow 0.3s',
      margin: '10px',
      cursor: 'pointer',
      fontFamily: "'Poppins', sans-serif",
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 'calc(33% - 20px)',
      minWidth: '250px'
    },
    jobCardLogo: {
      width: '60px',
      height: '60px',
      marginBottom: '20px',
      borderRadius: '50%',
      transition: 'transform 0.3s'
    },
    jobCardContent: {
      flex: 1
    },
    jobCardTitle: {
      fontSize: '1.2em',
      fontWeight: '600',
      marginBottom: '10px',
      transition: 'color 0.3s'
    },
    jobCardDetail: {
      margin: '5px 0',
      color: '#555',
      display: 'flex',
      alignItems: 'center',
      transition: 'color 0.3s'
    },
    jobCardDescription: {
      margin: '5px 0',
      color: '#555',
      transition: 'color 0.3s'
    },
    jobCardActions: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
      alignItems: 'center'
    },
    applyButton: {
      padding: '10px 20px',
      backgroundColor: 'rgb(10, 88, 202)',
      color: 'white',
      border: 'none',
      borderRadius: '30px',
      cursor: 'pointer',
      fontSize: '0.9em',
      transition: 'transform 0.3s, background-color 0.3s'
    },
    saveButton: {
      padding: '10px 20px',
      backgroundColor: 'white',
      color: 'rgb(10, 88, 202)',
      border: '1px solid rgb(10, 88, 202)',
      borderRadius: '30px',
      cursor: 'pointer',
      fontSize: '0.9em',
      transition: 'transform 0.3s, background-color 0.3s'
    },
    salary: {
      fontSize: '1.2em',
      fontWeight: '600',
      color: 'rgb(10, 88, 202)'
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
      marginBottom: '40px',
      flexWrap: 'wrap'
    },
    filterSelect: {
      padding: '10px',
      borderRadius: '20px',
      border: '1px solid #ddd',
      fontSize: '1em',
      fontFamily: "'Poppins', sans-serif",
      cursor: 'pointer',
      transition: 'border-color 0.3s',
      margin: '10px 0'
    }
  };

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <Navbar navClass="defaultscroll sticky" navLight={true} />
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '40px',
          background:
            'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(4,125,197,1) 0%, rgba(10,11,11,1) 0%, rgba(1,59,124,1) 100%, rgba(0,212,255,1) 100%)',
          backgroundSize: '400% 400%',
          animation: 'gradient 15s ease infinite',
          height: '100vh',
          position: 'relative',
          color: '#fff',
          fontFamily: "'Poppins', sans-serif",
          textAlign: 'center',
        }}
      >
        <style>
          {`@keyframes gradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }`}
        </style>
        <div style={{ flex: 1, padding: '20px', zIndex: 1 }}>
          <h1
            style={{
              fontSize: '3.5em',
              fontWeight: '700',
              margin: '0 0 20px 0',
            }}
          >
            Your Next Big Opportunity Awaits
          </h1>
          <p
            style={{
              fontSize: '1.2em',
              maxWidth: '600px',
              margin: '0 auto 30px',
              lineHeight: '1.7',
            }}
          >
            Join top companies and work on projects that challenge and inspire you. Your dream job is just a click away!
          </p>
          <div
            style={{
              fontSize: '1.2em',
              color: '#ddd',
              marginBottom: '20px',
            }}
          >
            Explore What's Trending:
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '15px',
              marginBottom: '20px',
              justifyContent: 'center',
            }}
          >
            {[
              'Software Engineering',
              'Frontend Development',
              'Project Management',
              'Marketing',
              'Finance',
              'Healthcare',
              'Education',
              'Legal',
              'UI/UX Design',
              'Customer Support'
            ].map((item, index) => (
              <span
                key={index}
                style={{
                  padding: '12px 25px',
                  backgroundColor: 'rgb(10, 88, 202)',
                  color: 'white',
                  borderRadius: '25px',
                  fontSize: '1em',
                  cursor: 'pointer',
                  transition: 'transform 0.3s',
                }}
              >
                {item}
              </span>
            ))}
          </div>
          <button
            style={{
              padding: '15px 30px',
              backgroundColor: 'rgb(10, 88, 202)',
              color: 'white',
              border: 'none',
              borderRadius: '30px',
              cursor: 'pointer',
              fontSize: '1.2em',
              display: 'flex',
              alignItems: 'center',
              transition: 'transform 0.3s',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <BsPeople style={{ marginRight: '10px' }} />
            Find Your Talent
          </button>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            flexWrap: 'wrap',
          }}
          className="hero-expert-card"
        >
          {candidates.length > 0 && (
            <ExpertCard candidate={candidates[currentCandidateIndex]} />
          )}
        </div>
        <style jsx>{`
          @media (max-width: 768px) {
            .hero-expert-card {
              display: none;
            }
            section {
              flex-direction: column;
              text-align: center;
              padding: 20px;
              height: auto;
            }
            h1 {
              font-size: 2.5em;
            }
            p {
              font-size: 1em;
            }
            button {
              width: 100%;
              margin-top: 20px;
              padding: 10px 0;
              font-size: 1em;
              border-radius: 25px;
            }
          }
        `}</style>
      </section>

      <div
        style={{
          textAlign: 'center',
          marginTop: '50px',
          fontSize: '1.5em',
          fontWeight: '600',
          color: '#333',
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        <h2>Meet Experts Who Make a Difference</h2>
        <p
          style={{
            fontSize: '1.2em',
            color: '#777',
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          Connect with global talent ready to take your projects to the next level. Discover professionals with proven track records.
        </p>
      </div>

      <section
        style={{
          padding: '60px 0',
          backgroundColor: '#f8f9fa',
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        <div className="container">
          <ServicesTwo />
          <AboutUs containerClass="container mt-100 mt-60" />

          {/* New Browse by Categories Section */}
          <div className="container mt-100 mt-60">
            <div
              style={{
                textAlign: 'center',
                marginBottom: '40px',
              }}
            >
              <h4
                style={{
                  fontSize: '2.5em',
                  fontWeight: '700',
                  color: '#333',
                }}
              >
                Explore by Categories
              </h4>
              <p
                style={{
                  fontSize: '1.2em',
                  color: '#777',
                }}
              >
                Discover the perfect role by browsing through our categories. Find the job that aligns with your skills and aspirations.
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
              }}
            >
              {categories.map((category, index) => {
                const jobsInCategory = jobs.filter(job => job.category === category);
                return (
                  <div
                    key={index}
                    style={{
                      width: '250px',
                      backgroundColor: '#fff',
                      padding: '20px',
                      borderRadius: '20px',
                      boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                      textAlign: 'center',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      cursor: 'pointer',
                      margin: '20px',
                    }}
                    onClick={() => handleCategoryClick(category)}
                  >
                    <h5
                      style={{
                        fontSize: '1.3em',
                        fontWeight: '600',
                        color: '#333',
                        marginBottom: '10px',
                      }}
                    >
                      {category}
                    </h5>
                    <p
                      style={{
                        fontSize: '1.1em',
                        color: '#777',
                        marginBottom: '10px',
                      }}
                    >
                      {`Explore ${jobsInCategory.length} jobs`}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Enhanced Job Listing Section */}
          <div className="container mt-100 mt-60" id="jobs-section">
            <div
              style={{
                textAlign: 'center',
                marginBottom: '40px',
              }}
            >
              <h4
                style={{
                  fontSize: '2.5em',
                  fontWeight: '700',
                  color: '#333',
                }}
              >
                Featured Opportunities
              </h4>
              <p
                style={{
                  fontSize: '1.2em',
                  color: '#777',
                }}
              >
                Browse through exclusive job listings from top companies worldwide. Where will your skills take you next?
              </p>
            </div>
            <div style={styles.filterContainer}>
              <select
                style={styles.filterSelect}
                onChange={e => setCategoryFilter(e.target.value)}
                value={categoryFilter}
              >
                <option value="">All Categories</option>
                {categories.map((category) => (
                  <option value={category} key={category}>{category}</option>
                ))}
              </select>
              <select
                style={styles.filterSelect}
                onChange={e => setLocationFilter(e.target.value)}
                value={locationFilter}
              >
                <option value="">All Locations</option>
                <option value="New York">New York</option>
                <option value="San Francisco">San Francisco</option>
                <option value="Los Angeles">Los Angeles</option>
                <option value="Chicago">Chicago</option>
                <option value="Austin">Austin</option>
              </select>
              <select
                style={styles.filterSelect}
                onChange={e => setTypeFilter(e.target.value)}
                value={typeFilter}
              >
                <option value="">All Types</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
                <option value="Internship">Internship</option>
              </select>
              <select
                style={styles.filterSelect}
                onChange={e => setSalaryFilter(e.target.value)}
                value={salaryFilter}
              >
                <option value="">All Salaries</option>
                <option value="Less than $1000">Less than $1000</option>
                <option value="$1000 - $15000">$1000 - $15000</option>
                <option value="More than $15000">More than $15000</option>
                <option value="Custom">Custom</option>
              </select>
              <select
                style={styles.filterSelect}
                onChange={e => setExperienceFilter(e.target.value)}
                value={experienceFilter}
              >
                <option value="">All Experience</option>
                <option value="Less than a year">Less than a year</option>
                <option value="1-3 years">1-3 years</option>
                <option value="3-5 years">3-5 years</option>
                <option value="5-10 years">5-10 years</option>
                <option value="More than 10 years">More than 10 years</option>
              </select>
            </div>
            <div
              className="row g-4 mt-0"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
              }}
            >
              {filteredJobs
                .slice((currentPage - 1) * jobsPerPage, currentPage * jobsPerPage)
                .map(job => (
                  <JobCard key={job.id} job={job} />
                ))}
            </div>
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              {Array.from({ length: Math.ceil(filteredJobs.length / jobsPerPage) }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  style={{
                    padding: '10px 20px',
                    margin: '5px',
                    backgroundColor: currentPage === index + 1 ? 'rgb(10, 88, 202)' : '#fff',
                    color: currentPage === index + 1 ? '#fff' : 'rgb(10, 88, 202)',
                    border: '1px solid rgb(10, 88, 202)',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>

          <Blog />
        </div>
      </section>
      <ScrollTop />
      <Footer />
    </>
  );
};

export default IndexTwo;
