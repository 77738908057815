import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { collection, getDocs, addDoc, setDoc, serverTimestamp, doc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import ClientSidebar from './clientsidebar';
import ClientNavbar from './clientnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import styled from 'styled-components';
import { FaEnvelope, FaUserCircle, FaVideo, FaSearch, FaPhone, FaCalendarAlt, FaHeart } from 'react-icons/fa';
import heroImage from '../assets/images/hero/bg3.jpg';

const themeColor = 'rgb(10, 88, 202)';
const secondaryColor = '#2c3e50';
const backgroundColor = '#f9fbfd';

const industryCategories = [
  { value: 'marketing', label: 'Marketing' },
  { value: 'fullstack_engineer', label: 'Fullstack Engineer' },
  { value: 'data_science', label: 'Data Science' },
  { value: 'product_management', label: 'Product Management' },
  { value: 'graphic_design', label: 'Graphic Design' },
  { value: 'ui_ux_design', label: 'UI/UX Design' },
  { value: 'software_development', label: 'Software Development' },
  { value: 'project_management', label: 'Project Management' },
  { value: 'sales', label: 'Sales' },
  { value: 'customer_support', label: 'Customer Support' },
  { value: 'human_resources', label: 'Human Resources' },
  { value: 'finance', label: 'Finance' },
  { value: 'legal', label: 'Legal' },
  { value: 'education', label: 'Education' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'real_estate', label: 'Real Estate' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'other', label: 'Other' }
];

const skillsByCategory = {
  marketing: [
    { value: 'seo', label: 'SEO' },
    { value: 'digital_advertising', label: 'Digital Advertising' },
    { value: 'content_strategy', label: 'Content Strategy' },
    { value: 'social_media', label: 'Social Media' },
    { value: 'email_marketing', label: 'Email Marketing' },
    { value: 'brand_management', label: 'Brand Management' },
    { value: 'analytics', label: 'Analytics' },
    { value: 'ppc', label: 'PPC' },
    { value: 'content_creation', label: 'Content Creation' },
    { value: 'video_marketing', label: 'Video Marketing' },
    { value: 'market_research', label: 'Market Research' },
    { value: 'consumer_behavior', label: 'Consumer Behavior' },
    { value: 'strategic_planning', label: 'Strategic Planning' },
    { value: 'crm', label: 'CRM' },
    { value: 'public_relations', label: 'Public Relations' },
    { value: 'event_planning', label: 'Event Planning' },
    { value: 'copywriting', label: 'Copywriting' },
    { value: 'influencer_marketing', label: 'Influencer Marketing' },
    { value: 'affiliate_marketing', label: 'Affiliate Marketing' },
    { value: 'graphic_design', label: 'Graphic Design' }
  ],
  fullstack_engineer: [
    { value: 'javascript', label: 'JavaScript' },
    { value: 'react', label: 'React' },
    { value: 'node_js', label: 'Node.js' },
    { value: 'express_js', label: 'Express.js' },
    { value: 'mongodb', label: 'MongoDB' },
    { value: 'sql', label: 'SQL' },
    { value: 'html', label: 'HTML' },
    { value: 'css', label: 'CSS' },
    { value: 'typescript', label: 'TypeScript' },
    { value: 'graphql', label: 'GraphQL' },
    { value: 'rest_apis', label: 'REST APIs' },
    { value: 'docker', label: 'Docker' },
    { value: 'kubernetes', label: 'Kubernetes' },
    { value: 'aws', label: 'AWS' },
    { value: 'ci_cd', label: 'CI/CD' },
    { value: 'unit_testing', label: 'Unit Testing' },
    { value: 'integration_testing', label: 'Integration Testing' },
    { value: 'git', label: 'Git' },
    { value: 'agile_methodology', label: 'Agile Methodology' }
  ],
  data_science: [
    { value: 'python', label: 'Python' },
    { value: 'r', label: 'R' },
    { value: 'sql', label: 'SQL' },
    { value: 'machine_learning', label: 'Machine Learning' },
    { value: 'deep_learning', label: 'Deep Learning' },
    { value: 'data_visualization', label: 'Data Visualization' },
    { value: 'statistics', label: 'Statistics' },
    { value: 'big_data', label: 'Big Data' },
    { value: 'natural_language_processing', label: 'Natural Language Processing' },
    { value: 'data_mining', label: 'Data Mining' },
    { value: 'etl', label: 'ETL' },
    { value: 'data_cleaning', label: 'Data Cleaning' },
    { value: 'data_wrangling', label: 'Data Wrangling' },
    { value: 'predictive_modeling', label: 'Predictive Modeling' },
    { value: 'a_b_testing', label: 'A/B Testing' },
    { value: 'tensorflow', label: 'TensorFlow' },
    { value: 'pytorch', label: 'PyTorch' },
    { value: 'keras', label: 'Keras' },
    { value: 'pandas', label: 'Pandas' },
    { value: 'numpy', label: 'NumPy' }
  ],
  // Add more categories and their skills here
};

const countries = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'uk', label: 'UK' },
  { value: 'australia', label: 'Australia' },
  { value: 'germany', label: 'Germany' },
  { value: 'france', label: 'France' },
  { value: 'spain', label: 'Spain' },
  { value: 'italy', label: 'Italy' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'india', label: 'India' },
  { value: 'china', label: 'China' },
  { value: 'japan', label: 'Japan' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'mexico', label: 'Mexico' }
];

function ClientDashboard() {
  const navigate = useNavigate();
  const [candidatesData, setCandidatesData] = useState([]);
  const [showMessagingPopup, setShowMessagingPopup] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [filter, setFilter] = useState({ text: '', industry: '', skill: '', country: '' });
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, currentUser => {
      if (!currentUser) {
        navigate('/clientlogin');
      } else {
        fetchCandidatesData();
      }
    });
    return () => unsubscribeAuth();
  }, [navigate]);

  const signOutHandler = async () => {
    await firebaseSignOut(auth);
    navigate('/clientlogin');
  };

  const handleOpenMessagePopup = (candidateEmail) => {
    const selectedCandidate = candidatesData.find(candidate => candidate.email === candidateEmail);
    setSelectedCandidate(selectedCandidate);
    setShowMessagingPopup(true);
  };

  const handleCloseMessagePopup = () => {
    setShowMessagingPopup(false);
    setSelectedCandidate(null);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter(prev => ({ ...prev, [name]: value.toLowerCase() }));
  };

  const fetchCandidatesData = async () => {
    const snapshot = await getDocs(collection(db, "profiles"));
    setCandidatesData(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const addFavorite = async (candidate) => {
    const user = auth.currentUser;
    try {
      await addDoc(collection(db, 'favorites'), {
        userId: user.uid,
        candidateId: candidate.id,
        timestamp: serverTimestamp()
      });
      await addDoc(collection(db, 'notifications'), {
        userId: user.uid,
        message: `You added ${candidate.name} as a favorite`,
        timestamp: serverTimestamp()
      });
      setNotification(`You added ${candidate.name} as a favorite`);
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      console.error("Error adding favorite: ", error);
    }
  };

  const filteredCandidates = candidatesData.filter(candidate => {
    const matchesText = candidate.name.toLowerCase().includes(filter.text) || candidate.currentJobTitle.toLowerCase().includes(filter.text);
    const matchesIndustry = filter.industry === '' || candidate.industry?.toLowerCase() === filter.industry;
    const matchesSkill = filter.skill === '' || (candidate.skills && candidate.skills.map(skill => skill.toLowerCase()).includes(filter.skill));
    const matchesCountry = filter.country === '' || candidate.country?.toLowerCase() === filter.country;
    return matchesText && matchesIndustry && matchesSkill && matchesCountry;
  });

  return (
    <>
      {notification && <NotificationPopup>{notification}</NotificationPopup>}
      <ClientNavbarWrapper>
        <ClientNavbar signOutHandler={signOutHandler} />
      </ClientNavbarWrapper>
      <MainContainer>
        <Sidebar>
          <ClientSidebar />
        </Sidebar>
        <Content>
          <HeroSection>
            <HeroTitle>Find the Best Candidates</HeroTitle>
            <HeroSubtitle>Discover talented professionals for your team</HeroSubtitle>
          </HeroSection>
          <BookACallSection navigate={navigate} />
          <SearchContainer>
            <SearchInputGroup>
              <SearchIcon />
              <SearchInput
                type="text"
                placeholder="Search by keyword..."
                onChange={handleFilterChange}
                name="text"
              />
            </SearchInputGroup>
            <SearchButton>Search</SearchButton>
          </SearchContainer>
          <FilterContainer>
            <FilterGroup>
              <FilterLabel>Industry</FilterLabel>
              <FilterSelect name="industry" onChange={handleFilterChange}>
                <option value="">Select Industry</option>
                {industryCategories.map((industry) => (
                  <option key={industry.value} value={industry.value}>{industry.label}</option>
                ))}
              </FilterSelect>
            </FilterGroup>
            <FilterGroup>
              <FilterLabel>Skills</FilterLabel>
              <FilterSelect name="skill" onChange={handleFilterChange}>
                <option value="">Select Skill</option>
                {Object.keys(skillsByCategory).map((category) =>
                  skillsByCategory[category].map((skill) => (
                    <option key={skill.value} value={skill.value}>{skill.label}</option>
                  ))
                )}
              </FilterSelect>
            </FilterGroup>
            <FilterGroup>
              <FilterLabel>Country</FilterLabel>
              <FilterSelect name="country" onChange={handleFilterChange}>
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.value} value={country.value}>{country.label}</option>
                ))}
              </FilterSelect>
            </FilterGroup>
          </FilterContainer>
          <CandidatesSection candidates={filteredCandidates} handleOpenMessagePopup={handleOpenMessagePopup} addFavorite={addFavorite} />
        </Content>
      </MainContainer>
      {showMessagingPopup && (
        <MessagingPopup
          onClose={handleCloseMessagePopup}
          selectedUser={selectedCandidate}
        />
      )}
      <Footer />
      <ScrollTop />
    </>
  );
}

const HeroSection = styled.div`
  background-image: url(${heroImage});
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background-size: cover;
  background-position: center;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  max-width: 700px;
  padding: 0 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
`;

const ClientNavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const MainContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${backgroundColor};
  margin-top: 70px; /* Adjusted to account for the fixed navbar height */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  flex-shrink: 0;
  width: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    box-shadow: none;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const SearchInputGroup = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const SearchIcon = styled(FaSearch)`
  margin-right: 10px;
  color: ${themeColor};
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 0.9rem;
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  background-color: ${themeColor};
  color: #fff;
  border: none;
  border-radius: 30px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }

  &:hover {
    background-color: #084DC2;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterGroup = styled.div`
  flex: 1;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const FilterLabel = styled.label`
  font-size: 0.9rem;
  color: ${secondaryColor};
  margin-bottom: 5px;
  display: block;
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 30px;
  border: 1px solid #ddd;
`;

const BookACallSection = ({ navigate }) => {
  const sectionStyle = {
    padding: '20px',
    background: `url(https://via.placeholder.com/600) no-repeat center center`,
    backgroundSize: 'cover',
    marginBottom: '20px',
    borderRadius: '10px',
    transition: 'transform 0.3s ease-in-out'
  };

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    backdropFilter: 'blur(12px)',
    boxShadow: '0 4px 16px rgba(0,0,0,0.15)',
    transition: 'background-color 0.3s ease'
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: themeColor,
    color: '#fff',
    border: 'none',
    borderRadius: '30px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, transform 0.2s',
    display: 'flex',
    alignItems: 'center'
  };

  return (
    <div style={sectionStyle} onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.03)'} onMouseLeave={e => e.currentTarget.style.transform = 'none'}>
      <div style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1)'}>
        <h2 style={{ color: '#333', marginBottom: '10px' }}>Connect with Experts Too </h2>
        <p style={{ color: '#666' }}>
          <FaPhone /> Let's discuss how we can tailor our expertise to your needs.
        </p>
        <button
          onClick={() => navigate('/bookacall')}
          style={buttonStyle}
          onMouseEnter={e => {
            e.currentTarget.style.backgroundColor = '#084DC2';
            e.currentTarget.style.transform = 'translateY(-2px)';
          }}
          onMouseLeave={e => {
            e.currentTarget.style.backgroundColor = themeColor;
            e.currentTarget.style.transform = 'translateY(0)';
          }}
        >
          <FaCalendarAlt style={{ marginRight: '5px' }} />
          Schedule a Call
        </button>
      </div>
    </div>
  );
};

const CandidatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CandidateCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(25% - 20px);
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1024px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 3px solid ${themeColor};
`;

const CandidateInfo = styled.div`
  text-align: center;
  color: #495057;
`;

const CandidateName = styled.h5`
  font-size: 1rem;
  font-weight: bold;
  color: ${secondaryColor};
  margin: 5px 0;
`;

const CandidateDescription = styled.p`
  font-size: 0.9rem;
  color: ${secondaryColor};
  margin: 5px 0;
`;

const Experience = styled.p`
  font-size: 0.9rem;
  color: ${secondaryColor};
  margin: 5px 0;
`;

const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const Badge = styled.span`
  background-color: ${themeColor};
  color: #fff;
  border-radius: 12px;
  padding: 3px 8px;
  margin: 2px;
  font-size: 0.7rem;
`;

const ActionButton = styled.button`
  margin-top: 10px;
  padding: 6px 10px;
  background-color: ${themeColor};
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: #084DC2;
  }

  svg {
    margin-right: 5px;
  }
`;

const LoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px 8px;
  background-color: transparent;
  color: #e74c3c;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #c0392b;
  }

  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -30px;
    right: 0;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.7rem;
    white-space: nowrap;
  }
`;

const VideoPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 640px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const NotificationPopup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 10000;
`;

function CandidatesSection({ candidates, handleOpenMessagePopup, addFavorite }) {
  const navigate = useNavigate();
  const [videoPopup, setVideoPopup] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const openProfilePage = (candidateEmail) => {
    navigate(`/candidate-profile/${candidateEmail}`);
  };

  const showVideoPopup = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setVideoPopup(true);
  };

  const closeVideoPopup = () => {
    setVideoPopup(false);
    setCurrentVideoUrl('');
  };

  return (
    <>
      <CandidatesContainer>
        {candidates.map((candidate) => (
          <CandidateCard key={candidate.id}>
            <ProfileImage src={candidate.profileImage || 'https://via.placeholder.com/120'} alt="Profile" />
            <CandidateInfo>
              <CandidateName>{candidate.name}</CandidateName>
              <CandidateDescription>{candidate.currentJobTitle}</CandidateDescription>
              <CandidateDescription>{candidate.country}</CandidateDescription>
              <Experience>Experience: {candidate.experienceYears} years</Experience>
              <BadgeContainer>
                {candidate.skills && candidate.skills.slice(0, 4).map((skill, index) => (
                  <Badge key={index}>{skill}</Badge>
                ))}
              </BadgeContainer>
            </CandidateInfo>
            <ActionButton onClick={() => handleOpenMessagePopup(candidate.email)}>
              <FaEnvelope /> Message
            </ActionButton>
            <ActionButton onClick={() => openProfilePage(candidate.email)}>
              <FaUserCircle /> View Profile
            </ActionButton>
            {candidate.videoIntroUrl && (
              <ActionButton onClick={() => showVideoPopup(candidate.videoIntroUrl)}>
                <FaVideo /> Intro Video
              </ActionButton>
            )}
            <LoveButton onClick={() => addFavorite(candidate)} data-tooltip={`Click to add ${candidate.name} as a favorite`}>
              <FaHeart />
            </LoveButton>
          </CandidateCard>
        ))}
      </CandidatesContainer>
      {videoPopup && (
        <VideoPopup>
          <iframe
            width="100%"
            height="360"
            src={currentVideoUrl}
            title="Candidate Intro Video"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <button onClick={closeVideoPopup} style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#dc3545',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'block',
            marginLeft: 'auto',
          }}>Close</button>
        </VideoPopup>
      )}
    </>
  );
}

function MessagingPopup({ onClose, selectedUser }) {
  const [messageText, setMessageText] = useState('');

  const handleMessageChange = e => setMessageText(e.target.value);

  const handleSendMessage = async () => {
    if (!messageText.trim()) {
      console.error("Cannot send an empty message.");
      return;
    }
    try {
      const user = auth.currentUser;
      const conversationId = [user.email, selectedUser.email].sort().join('_');
      const conversationRef = doc(db, `messages/${conversationId}`);
      await setDoc(conversationRef, {
        participants: [user.email, selectedUser.email]
      });
      await addDoc(collection(conversationRef, 'messages'), {
        senderId: user.uid,
        receiverId: selectedUser.id,
        senderEmail: user.email,
        receiverEmail: selectedUser.email,
        text: messageText,
        timestamp: serverTimestamp()
      });
      console.log(`Message sent to ${selectedUser?.name}: "${messageText}"`);
      onClose();
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  return (
    <Modal>
      <ModalContent>
        <ModalCloseButton onClick={onClose}>×</ModalCloseButton>
        <h2>Message {selectedUser?.name}</h2>
        <textarea
          value={messageText}
          onChange={handleMessageChange}
          rows="5"
          style={{ width: '100%', padding: '10px', fontSize: '1rem', borderRadius: '5px', border: '1px solid #ccc' }}
        ></textarea>
        <ModalActions>
          <ActionButton onClick={handleSendMessage}>
            Send Message
          </ActionButton>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
}

// Styled components for Modal
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1050;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 15px 40px rgba(0,0,0,0.4);
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  color: #666;
  &:hover {
    color: #222;
    cursor: pointer;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export default ClientDashboard;
