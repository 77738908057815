import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { auth, googleAuthProvider, db } from '../firebase';
import Modal from 'react-modal';
import styled from 'styled-components';
import { doc, getDoc, setDoc } from 'firebase/firestore';

Modal.setAppElement('#root');

// Utility function to extract user's name from Firebase User object
const extractUserName = (firebaseUser) => {
    return firebaseUser.displayName || 'User';
};

const ClientLoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                const userData = {
                    uid: firebaseUser.uid,
                    email: firebaseUser.email,
                    displayName: extractUserName(firebaseUser),
                    photoURL: firebaseUser.photoURL,
                };
                setUser(userData);
                await checkUserSubscription(userData);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    // Check user subscription in Firestore
    const checkUserSubscription = async (userData) => {
        const userRef = doc(db, 'clientpricing', userData.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists() && docSnap.data().subscribed) {
            navigate('/clientdashboard', { state: { user: { ...userData, subscribed: true } } });
        } else {
            navigate('/pricing', { state: { user: userData } });
        }

        // Send user data to the Zapier webhook after subscription check
        sendUserToZapier(userData);
    };

    // Send user data to Zapier via the backend
    const sendUserToZapier = async (userData) => {
        const userPayload = {
            fullName: userData.displayName || 'Unknown User',
            email: userData.email,
            subscribed: true,
            timestamp: new Date().toISOString(),
        };

        console.log("Sending user data to server:", userPayload);  // Log payload for debugging

        try {
            const response = await fetch("https://expertstoo.com/send-to-zapier", {  // Backend URL
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userPayload),
            });

            if (!response.ok) {
                console.error("Failed to send data to server:", response.statusText);
                return;
            }

            const responseData = await response.json();
            console.log("Response from server:", responseData);
        } catch (error) {
            console.error("Error sending data to server:", error);
        }
    };

    const handleLoginWithEmail = async () => {
        if (!termsAccepted) {
            alert('You must accept the terms and conditions to continue.');
            return;
        }
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const { user } = userCredential;
            await checkUserSubscription(user);
        } catch (error) {
            alert('Failed to log in: ' + error.message);
        }
    };

    const handleLoginWithGoogle = async () => {
        if (!termsAccepted) {
            alert('Please accept the terms and conditions to continue.');
            return;
        }
        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const { user } = result;
            const userData = {
                uid: user.uid,
                email: user.email,
                displayName: extractUserName(user),
                photoURL: user.photoURL,
            };
            await checkUserSubscription(userData);
        } catch (error) {
            alert('Failed to log in with Google: ' + error.message);
        }
    };

    const handleResetPassword = () => {
        navigate('/reset-password');
    };

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    return (
        <Container>
            <FormSide>
                <FormContainer>
                    <Title>Welcome Back, Client!</Title>
                    <Description>Enter your details to log in to ExpertsToo as a Client.</Description>
                    <StyledInput 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                    />
                    <StyledInput 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                    <CheckboxContainer>
                        <label>
                            <Checkbox 
                                type="checkbox" 
                                checked={termsAccepted} 
                                onChange={() => setTermsAccepted(!termsAccepted)} 
                            />
                            Accept Terms and Conditions
                        </label>
                        <TermsLink onClick={toggleModal}>View Terms</TermsLink>
                    </CheckboxContainer>
                    <StyledButton onClick={handleLoginWithEmail}>Log In</StyledButton>
                    <StyledButton onClick={handleLoginWithGoogle} style={{ backgroundColor: '#db4437' }}>Continue with Google</StyledButton>
                    <LinkText onClick={handleResetPassword}>Forgot password?</LinkText>
                    <LinkText onClick={() => navigate('/clientregister')}>Don't have an account? Sign up</LinkText>
                </FormContainer>
            </FormSide>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Terms and Conditions"
                style={modalStyle}>
                <ModalTitle>Terms and Conditions</ModalTitle>
                <ModalContent>Here is a sample of terms and conditions. Please read and accept to continue.</ModalContent>
                <StyledButton onClick={toggleModal} style={{ backgroundColor: '#007bff' }}>Close</StyledButton>
            </Modal>
        </Container>
    );
};

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
`;

const FormSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 450px;
`;

const FormContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const TermsLink = styled.span`
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px 0;
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const LinkText = styled.span`
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
  margin-top: 10px;
`;

const ModalTitle = styled.h2`
  color: #333;
  margin-bottom: 10px;
`;

const ModalContent = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        padding: '20px',
        borderRadius: '10px',
    }
};

export default ClientLoginScreen;
