import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, setDoc, getDoc, getDocs, collection, query, where, limit, startAfter, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock, faBriefcase, faHeart, faBrain, faMapMarkedAlt, faDollarSign,
  faCalendarAlt, faGraduationCap, faGlobe, faIndustry, faQuestionCircle,
  faLayerGroup, faBuilding, faFlag, faTimes, faThumbsUp, faList,
  faSearch, faFilter, faChevronDown, faChevronUp, faStar, faUser
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import ExpertSidebar from './expertsidebar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import ExpertNavbar from './expertnavbar';
import { auth, db } from '../firebase';
import toast from 'react-hot-toast';

const themeColor = '#007bff';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const WelcomeMessage = styled.h1`
  font-size: 2rem;
  color: ${themeColor};
`;

const StatsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const StatCard = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const StatValue = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${themeColor};
`;

const StatLabel = styled.div`
  font-size: 0.875rem;
  color: #6c757d;
`;

const JobSection = styled.div`
  display: flex;
  gap: 2rem;
`;

const JobList = styled.div`
  flex: 2;
`;

const JobCard = styled(motion.div)`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const JobHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
`;

const JobLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

const JobTitle = styled.h3`
  margin: 0;
  font-size: 1.125rem;
  color: ${themeColor};
`;

const JobCompany = styled.p`
  margin: 0.25rem 0 0;
  font-size: 0.875rem;
  color: #6c757d;
`;

const JobDetails = styled.div`
  padding: 1rem;
`;

const JobInfo = styled.p`
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: #495057;

  svg {
    margin-right: 0.5rem;
    color: ${themeColor};
  }
`;

const JobDescription = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const JobDetailsPanel = styled(motion.div)`
  flex: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: sticky;
  top: 2rem;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
`;

const JobDetailHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const JobDetailLogo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`;

const JobDetailTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  color: ${themeColor};
`;

const JobDetailCompany = styled.p`
  margin: 0.25rem 0 0;
  font-size: 1rem;
  color: #6c757d;
`;

const JobDetailInfo = styled.p`
  margin: 0.75rem 0;
  font-size: 0.9375rem;
  color: #495057;

  svg {
    margin-right: 0.5rem;
    color: ${themeColor};
  }
`;

const JobDetailDescription = styled.div`
  margin-top: 1.5rem;
  font-size: 0.9375rem;
  color: #495057;
  line-height: 1.6;
`;

const JobActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const ActionButton = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:first-child {
    background-color: #e9ecef;
    color: ${themeColor};

    &:hover {
      background-color: #dee2e6;
    }
  }

  &:last-child {
    background-color: ${themeColor};
    color: white;

    &:hover {
      background-color: #0056b3;
    }
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ToggleButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const ToggleButton = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: ${props => props.active ? themeColor : 'white'};
  color: ${props => props.active ? 'white' : themeColor};
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:last-child) {
    border-right: 1px solid #e9ecef;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const SearchAndFilter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${themeColor};
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const FilterDropdown = styled.select`
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  margin-left: 1rem;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${themeColor};
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const LoadMoreButton = styled.button`
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: none;
  background-color: #e9ecef;
  color: ${themeColor};
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1.5rem;

  &:hover {
    background-color: #dee2e6;
  }
`;

const PersonalityReport = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 2rem;
`;

const PersonalityReportTitle = styled.h2`
  font-size: 1.5rem;
  color: ${themeColor};
  margin-bottom: 1rem;
`;

const PersonalityTrait = styled.div`
  margin-bottom: 1rem;
`;

const TraitName = styled.h3`
  font-size: 1.125rem;
  color: #495057;
  margin-bottom: 0.5rem;
`;

const TraitDescription = styled.p`
  font-size: 0.9375rem;
  color: #6c757d;
  line-height: 1.6;
`;

const ExpertDashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [jobs, setJobs] = useState([]);
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [aiRecommendedJobs, setAIRecommendedJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');
  const [showRecommended, setShowRecommended] = useState(false);
  const [showAIRecommended, setShowAIRecommended] = useState(false);
  const [newJobsLoaded, setNewJobsLoaded] = useState(false);
  const [personalityReport, setPersonalityReport] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchUserProfile(user.email);
    }
  }, [user]);

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [jobs, recommendedJobs, aiRecommendedJobs, searchTerm, filterIndustry, showRecommended, showAIRecommended]);

  const fetchUserProfile = async (email) => {
    try {
      const userProfileQuery = query(collection(db, 'profiles'), where('email', '==', email));
      const userProfileSnapshot = await getDocs(userProfileQuery);
      const userProfile = userProfileSnapshot.docs[0]?.data();
      if (userProfile?.industry) {
        fetchRecommendedJobs(userProfile.industry);
        fetchAIRecommendedJobs(userProfile);
      }
      fetchPersonalityReport(email);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchJobs = async () => {
    try {
      const jobsQuery = query(collection(db, "jobs"), limit(10));
      const querySnapshot = await getDocs(jobsQuery);
      const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(sortJobsByInfo(jobsData));
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      if (jobsData.length > 0) {
        setSelectedJob(jobsData[0]);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const fetchMoreJobs = async () => {
    if (lastVisible && !loading) {
      try {
        const moreJobsQuery = query(collection(db, "jobs"), startAfter(lastVisible), limit(10));
        const querySnapshot = await getDocs(moreJobsQuery);
        const moreJobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setJobs(sortJobsByInfo([...jobs, ...moreJobsData]));
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } catch (error) {
        console.error("Error fetching more jobs:", error);
      }
    }
  };

  const fetchRecommendedJobs = async (industry) => {
    try {
      const similarIndustries = getSimilarIndustries(industry);
      const recommendedJobsQuery = query(collection(db, 'jobs'), where('industry', 'in', similarIndustries), limit(10));
      const recommendedJobsSnapshot = await getDocs(recommendedJobsQuery);
      const recommendedJobsData = recommendedJobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRecommendedJobs(recommendedJobsData);
    } catch (error) {
      console.error("Error fetching recommended jobs:", error);
    }
  };

  const fetchAIRecommendedJobs = async (userProfile) => {
    try {
      const allJobsSnapshot = await getDocs(collection(db, 'jobs'));
      const jobsData = allJobsSnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        description: doc.data().description,
        industry: doc.data().industry
      }));

      const prompt = `
        Based on the user profile and available jobs, recommend the top 5 most suitable jobs.
        Consider the user's skills, experience, and industry preferences.

        User Profile:
        ${JSON.stringify(userProfile)}

        Available Jobs:
        ${JSON.stringify(jobsData)}

        Provide the recommendations in the following format:
        1. [Job ID]: [Reason for recommendation]
        2. [Job ID]: [Reason for recommendation]
        ...
      `;

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are an AI assistant specialized in job recommendations."
            },
            {
              role: "user",
              content: prompt
            }
          ],
          max_tokens: 500,
          n: 1,
          stop: null,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const result = response.data.choices[0].message.content.trim();
      const recommendedJobIds = result.split('\n').map(line => {
        const match = line.match(/^\d+\.\s*(\w+):/);
        return match ? match[1] : null;
      }).filter(id => id !== null);

      const aiRecommendedJobsData = jobsData.filter(job => recommendedJobIds.includes(job.id));
      setAIRecommendedJobs(aiRecommendedJobsData);
      setNewJobsLoaded(true);
      saveAIRecommendedJobs(aiRecommendedJobsData);

    } catch (error) {
      console.error("Error fetching AI recommended jobs:", error);
      setAIRecommendedJobs(await loadSavedAIRecommendedJobs());
    }
  };

  const saveAIRecommendedJobs = async (jobs) => {
    if (user) {
      try {
        const aiJobsDocRef = doc(db, "users", user.uid, "aiRecommendedJobs", "latest");
        await setDoc(aiJobsDocRef, { jobs });
      } catch (error) {
        console.error("Error saving AI recommended jobs:", error);
      }
    }
  };

  const loadSavedAIRecommendedJobs = async () => {
    if (user) {
      try {
        const aiJobsDocRef = doc(db, "users", user.uid, "aiRecommendedJobs", "latest");
        const aiJobsSnapshot = await getDoc(aiJobsDocRef);
        return aiJobsSnapshot.exists() ? aiJobsSnapshot.data()?.jobs || [] : [];
      } catch (error) {
        console.error("Error loading saved AI recommended jobs:", error);
        return [];
      }
    }
    return [];
  };

  const fetchPersonalityReport = async (email) => {
    try {
      const personalityReportRef = doc(db, 'personalityReports', email);
      const personalityReportSnapshot = await getDoc(personalityReportRef);
      if (personalityReportSnapshot.exists()) {
        setPersonalityReport(personalityReportSnapshot.data());
      }
    } catch (error) {
      console.error("Error fetching personality report:", error);
    }
  };
  const skillsByCategory = {
    "Marketing": ["SEO", "Digital Advertising", "Content Strategy", "Social Media", "Email Marketing", "Brand Management", "Analytics", "PPC", "Content Creation", "Video Marketing"],
    "Fullstack Engineer": ["JavaScript", "React", "Node.js", "SQL", "NoSQL", "CSS", "HTML", "Angular", "Vue.js", "Python"],
    "Data Science": ["Python", "R", "SQL", "Machine Learning", "Deep Learning", "Data Visualization", "Big Data", "Statistics", "Data Mining", "NLP"],
    "Product Management": ["Agile", "Scrum", "User Stories", "Roadmapping", "A/B Testing", "Product Strategy", "User Research", "Wireframing", "Prototyping", "Stakeholder Management"],
    "Graphic Design": ["Adobe Photoshop", "Adobe Illustrator", "InDesign", "Typography", "Logo Design", "Branding", "Color Theory", "Print Design", "Digital Illustration", "UI Design"],
    "UI/UX Design": ["Wireframing", "Prototyping", "User Research", "Usability Testing", "Information Architecture", "Interaction Design", "Visual Design", "Sketch", "Figma", "Adobe XD"],
    "Software Development": ["Java", "Python", "C++", "C#", "Ruby", "Go", "Swift", "Kotlin", "PHP", "Rust"],
    "Project Management": ["Agile", "Scrum", "Kanban", "PRINCE2", "PMP", "Risk Management", "Stakeholder Management", "Budgeting", "Resource Allocation", "Gantt Charts"],
    "Sales": ["Negotiation", "CRM", "Lead Generation", "Cold Calling", "Account Management", "Sales Strategy", "Presentations", "Closing Techniques", "Pipeline Management", "Forecasting"],
    "Customer Support": ["Ticketing Systems", "Live Chat", "Phone Support", "Email Support", "Customer Service", "Conflict Resolution", "Product Knowledge", "Active Listening", "Empathy", "Problem-Solving"],
    "Human Resources": ["Recruitment", "Employee Relations", "Performance Management", "Training & Development", "Compensation & Benefits", "HRIS", "Labor Laws", "Onboarding", "Succession Planning", "Diversity & Inclusion"],
    "Finance": ["Financial Analysis", "Budgeting", "Forecasting", "Accounting", "Financial Modeling", "Risk Management", "Investment Analysis", "Tax Planning", "Audit", "Mergers & Acquisitions"],
    "Legal": ["Contract Law", "Intellectual Property", "Corporate Law", "Litigation", "Regulatory Compliance", "Legal Research", "Negotiation", "Mediation", "Legal Writing", "Ethics"],
    "Education": ["Curriculum Development", "Instructional Design", "E-learning", "Assessment", "Classroom Management", "Special Education", "Educational Technology", "Pedagogy", "Student Counseling", "STEM Education"],
    "Healthcare": ["Patient Care", "Electronic Health Records", "Medical Coding", "Clinical Research", "Healthcare Administration", "Nursing", "Pharmacology", "Medical Imaging", "Public Health", "Telemedicine"],
    "Real Estate": ["Property Valuation", "Real Estate Law", "Property Management", "Commercial Real Estate", "Residential Real Estate", "Real Estate Finance", "Land Development", "Real Estate Marketing", "Leasing", "Property Inspection"],
    "Logistics": ["Supply Chain Management", "Inventory Management", "Warehousing", "Transportation Management", "Freight Forwarding", "Customs Compliance", "Demand Planning", "Route Optimization", "Logistics Software", "Cold Chain Logistics"],
    "Manufacturing": ["Lean Manufacturing", "Six Sigma", "Quality Control", "Process Improvement", "Production Planning", "Inventory Management", "CAD/CAM", "CNC Programming", "3D Printing", "Industrial Automation"],
    "Hospitality": ["Hotel Management", "Food & Beverage Management", "Event Planning", "Customer Service", "Revenue Management", "Housekeeping", "Front Office Operations", "Reservation Systems", "Hospitality Marketing", "Tourism Management"],
    "Other": ["Communication", "Leadership", "Time Management", "Critical Thinking", "Adaptability", "Teamwork", "Problem Solving", "Creativity", "Emotional Intelligence", "Decision Making"]
  };
  
  const getSimilarIndustries = (industry) => {
    const industryMap = {
      "IT": ["IT", "Software Development", "Fullstack Development", "Web Development"],
      "Software Development": ["Software Development", "IT", "Fullstack Development", "Web Development"],
      "Fullstack Development": ["Fullstack Development", "IT", "Software Development", "Web Development"],
      "Marketing": ["Marketing", "Sales", "Advertising"],
      "Finance": ["Finance", "Accounting", "Banking"]
    };
    return industryMap[industry] || [industry];
  };

  const sortJobsByInfo = (jobsData) => {
    return jobsData.sort((a, b) => {
      const aInfoCount = Object.values(a).filter(val => val && typeof val !== 'object').length;
      const bInfoCount = Object.values(b).filter(val => val && typeof val !== 'object').length;
      return bInfoCount - aInfoCount;
    });
  };

  const applyFilters = () => {
    const selectedJobs = showAIRecommended
      ? aiRecommendedJobs
      : showRecommended
      ? recommendedJobs
      : jobs;

    const filtered = selectedJobs.filter((job) => {
      const jobTitle = job.title ? job.title.toLowerCase() : '';
      const industry = job.industry ? job.industry.toLowerCase() : '';
      return (
        jobTitle.includes(searchTerm.toLowerCase()) &&
        (!filterIndustry || industry === filterIndustry.toLowerCase())
      );
    });

    setFilteredJobs(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleIndustryChange = (e) => {
    setFilterIndustry(e.target.value);
  };

  const saveJob = async (jobId) => {
    if (user) {
      try {
        const jobDocRef = doc(db, "users", user.uid, "savedJobs", jobId);
        await setDoc(jobDocRef, { jobId });
        toast.success("Job saved successfully!");
      } catch (error) {
        console.error("Error saving job:", error);
        toast.error("Failed to save job. Please try again.");
      }
    }
  };

  const applyForJob = async (job) => {
    try {
      await updateDoc(doc(db, "jobs", job.id), {
        applicants: job.applicants ? job.applicants + 1 : 1
      });
      navigate('/job-apply', { state: { jobDetails: job } });
    } catch (error) {
      console.error("Error applying for job:", error);
      toast.error("Failed to apply for job. Please try again.");
    }
  };

  return (
    <>
      <ExpertNavbar />
      <Container>
        <ExpertSidebar />
        <MainContent>
          <DashboardHeader>
            <WelcomeMessage>Welcome back, {user?.displayName || 'Expert'}!</WelcomeMessage>
            <StatsContainer>
              <StatCard>
                <StatValue>{filteredJobs.length}</StatValue>
                <StatLabel>Jobs Available</StatLabel>
              </StatCard>
              <StatCard>
                <StatValue>{recommendedJobs.length}</StatValue>
                <StatLabel>Recommended Jobs</StatLabel>
              </StatCard>
              <StatCard>
                <StatValue>{aiRecommendedJobs.length}</StatValue>
                <StatLabel>AI Recommended Jobs</StatLabel>
              </StatCard>
            </StatsContainer>
          </DashboardHeader>

          <ToggleButtons>
            <ToggleButton
              active={!showRecommended && !showAIRecommended}
              onClick={() => { setShowRecommended(false); setShowAIRecommended(false); }}
            >
              <FontAwesomeIcon icon={faList} /> Available Jobs
            </ToggleButton>
            <ToggleButton
              active={showRecommended && !showAIRecommended}
              onClick={() => { setShowRecommended(true); setShowAIRecommended(false); }}
            >
              <FontAwesomeIcon icon={faThumbsUp} /> Recommended Jobs
            </ToggleButton>
            <ToggleButton
              active={showAIRecommended}
              onClick={() => { setShowAIRecommended(true); setShowRecommended(false); setNewJobsLoaded(false); }}
            >
              <FontAwesomeIcon icon={faBrain} /> AI Recommended Jobs
            </ToggleButton>
          </ToggleButtons>

          <SearchAndFilter>
            <SearchInput
              type="text"
              placeholder="Search job titles..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FilterDropdown onChange={handleIndustryChange} value={filterIndustry}>
              <option value="">All Industries</option>
              {Object.keys(skillsByCategory).map((industry) => (
                <option key={industry} value={industry}>{industry}</option>
              ))}
            </FilterDropdown>
          </SearchAndFilter>

          <JobSection>
            <JobList>
              <AnimatePresence>
                {filteredJobs.map((job) => (
                  <JobCard
                    key={job.id}
                    layoutId={job.id}
                    onClick={() => setSelectedJob(job)}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <JobHeader>
                      <JobLogo src={job.logo || 'https://via.placeholder.com/50'} alt={job.companyName} />
                      <div>
                        <JobTitle>{job.title}</JobTitle>
                        <JobCompany>{job.companyName}</JobCompany>
                      </div>
                    </JobHeader>
                    <JobDetails>
                      <JobInfo><FontAwesomeIcon icon={faBriefcase} /> {job.type}</JobInfo>
                      <JobInfo><FontAwesomeIcon icon={faMapMarkedAlt} /> {job.location}</JobInfo>
                      <JobInfo><FontAwesomeIcon icon={faDollarSign} /> {job.salary}</JobInfo>
                      <JobDescription>{job.description}</JobDescription>
                    </JobDetails>
                  </JobCard>
                ))}
              </AnimatePresence>
              {filteredJobs.length >= 10 && (
                <LoadMoreButton onClick={fetchMoreJobs}>
                  Load More Jobs
                </LoadMoreButton>
              )}
            </JobList>

            <AnimatePresence>
              {selectedJob && (
                <JobDetailsPanel
                  key={selectedJob.id}
                  layoutId={selectedJob.id}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <JobDetailHeader>
                    <JobDetailLogo src={selectedJob.logo || 'https://via.placeholder.com/60'} alt={selectedJob.companyName} />
                    <div>
                      <JobDetailTitle>{selectedJob.title}</JobDetailTitle>
                      <JobDetailCompany>{selectedJob.companyName}</JobDetailCompany>
                    </div>
                  </JobDetailHeader>
                  <JobDetailInfo><FontAwesomeIcon icon={faBriefcase} /> {selectedJob.type}</JobDetailInfo>
                  <JobDetailInfo><FontAwesomeIcon icon={faMapMarkedAlt} /> {selectedJob.location}</JobDetailInfo>
                  <JobDetailInfo><FontAwesomeIcon icon={faDollarSign} /> {selectedJob.salary}</JobDetailInfo>
                  <JobDetailInfo><FontAwesomeIcon icon={faCalendarAlt} /> Posted: {selectedJob.postedDate}</JobDetailInfo>
                  <JobDetailDescription>{selectedJob.description}</JobDetailDescription>
                  <JobActions>
                    <ActionButton onClick={() => saveJob(selectedJob.id)}>
                      <FontAwesomeIcon icon={faHeart} /> Save Job
                    </ActionButton>
                    <ActionButton onClick={() => applyForJob(selectedJob)}>
                      <FontAwesomeIcon icon={faThumbsUp} /> Apply Now
                    </ActionButton>
                  </JobActions>
                </JobDetailsPanel>
              )}
            </AnimatePresence>
          </JobSection>

          {personalityReport && (
            <PersonalityReport>
              <PersonalityReportTitle>Your Personality Report</PersonalityReportTitle>
              {Object.entries(personalityReport).map(([trait, description]) => (
                <PersonalityTrait key={trait}>
                  <TraitName>{trait}</TraitName>
                  <TraitDescription>{description}</TraitDescription>
                </PersonalityTrait>
              ))}
            </PersonalityReport>
          )}
        </MainContent>
      </Container>
      <ScrollTop />
      <Footer />
    </>
  );
};

export default ExpertDashboard;